import React, { FC, useEffect, useState } from 'react'
import { RequestFormProps, RequestInputs } from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import RadioInput from '@components/requestForms/inputs/radio.input'
import Modal from '@components/ui/modal'
import { makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'

const classes: { modalContent: string } = makeClasses({
	modalContent: {
		color: Colors.black2,
		fontSize: '16px',
		lineHeight: '26px',
		textAlign: 'center'
	}
})

const CrossingGuardAndSchoolForm: FC<RequestFormProps> = ({
	inputs,
	onInputsChange
}) => {
	const { pageAssets, UpdateRequestInputsArray, radioSelectionYesNo } =
		requestForm()

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

	const [recurringSituationInput, setRecurringSituationInput] =
		useState<RequestInputs>({
			name: 'recurringSituation',
			value: '',
			valueKey: '',
			label:
				pageAssets?.request_form_crossingGuardAndSchool_recurringSituation_label,
			labelKey: 'request_form_crossingGuardAndSchool_recurringSituation_label',
			required: false
		})

	const [crossingGuardInput, setCrossingGuardInput] = useState<RequestInputs>({
		name: 'crossingGuard',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_crossingGuardAndSchool_crossingGuard_label,
		labelKey: 'request_form_crossingGuardAndSchool_crossingGuard_label',
		required: false
	})

	const onSelectRecurringSituation = (value: string, valueKey: string) => {
		setRecurringSituationInput({ ...recurringSituationInput, value, valueKey })
	}

	const onSelectCrossingGuard = (value: string, valueKey: string) => {
		setCrossingGuardInput({ ...crossingGuardInput, value, valueKey })
	}

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, recurringSituationInput))
	}, [recurringSituationInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, crossingGuardInput))
	}, [crossingGuardInput])

	return (
		<>
			<RadioInput
				name={recurringSituationInput.name}
				label={recurringSituationInput.label}
				value={recurringSituationInput.value}
				list={radioSelectionYesNo}
				required={recurringSituationInput.required}
				onChange={onSelectRecurringSituation}
			/>

			<RadioInput
				name={crossingGuardInput.name}
				label={crossingGuardInput.label}
				value={crossingGuardInput.value}
				list={radioSelectionYesNo}
				required={crossingGuardInput.required}
				onChange={onSelectCrossingGuard}
			/>

			<Modal
				isOpen={isModalOpen}
				type={'warning'}
				openUsingSetTimeout
				cancelButtonText={pageAssets?.request_form_understood}
				onSetIsOpen={setIsModalOpen}
			>
				<div className={classes.modalContent}>
					{pageAssets?.request_form_crossingGuard_modal_text}
				</div>
			</Modal>
		</>
	)
}

export default CrossingGuardAndSchoolForm
