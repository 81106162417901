import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import { RequestFormProps, RequestInputs, SelectList } from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import SelectInput from '@components/requestForms/inputs/select.inputs'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'
import { useAppDispatch } from '@services/store'
import config from '@utils/config'
import { Request } from 'typings/shared'
import { populateRequestModelState } from '@services/store/request'
import RadioInput from './inputs/radio.input'
import { trafficLightAndPedestrianLightKeys } from './__forms.utils'

const TrafficLightAndPedestrianLight: FC<RequestFormProps> = ({
	inputs,
	errors,
	onInputsChange,
	onFixError
}) => {
	const dispatch = useAppDispatch()

	const {
		requests,
		pageAssets,
		displayWarningSection,
		filterMultipleRequests,
		UpdateRequestInputsArray,
		UpdateRequestInputsErrorsArray,
		radioSelectionYesNo
	} = requestForm()

	const [showSecondQuestion, setShowSecondQuestion] = useState<boolean>(false)
	const [showThirdQuestion, setShowThirdQuestion] = useState<boolean>(false)
	const [showAddLightInfo, setShowAddLightInfo] = useState<boolean>(false)
	const [showModifyLightInfo, setShowModifyLightInfo] = useState<boolean>(false)
	const [informationKey, setInformationKey] = useState<string>()

	const [requestTypeList, setRequestTypeList] = useState<SelectList[]>([])

	const [requestTypeInput, setRequestTypeInput] = useState<RequestInputs>({
		name: 'requestType',
		value: '',
		valueKey: '',
		label:
			pageAssets?.request_form_TrafficLightAndPedestrianLight_requestSubject_label,
		labelKey:
			'request_form_TrafficLightAndPedestrianLight_requestSubject_label',
		required: true,
		ref: useRef<HTMLSelectElement>(null)
	})

	const [lightingInterventionInput, setLightingInterventionInput] =
		useState<RequestInputs>({
			name: 'lightingIntervention',
			value: '',
			valueKey: '',
			label: pageAssets?.request_form_floorLampAndLighting_wish_label,
			labelKey: 'request_form_floorLampAndLighting_wish_label',
			required: true,
			ref: useRef<HTMLSelectElement>(null)
		})

	const [
		pedestrianLightInterventionInput,
		setPedestrianLightInterventionInput
	] = useState<RequestInputs>({
		name: 'lightingIntervention',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_floorLampAndLighting_wish_label,
		labelKey: 'request_form_floorLampAndLighting_wish_label',
		required: true,
		ref: useRef<HTMLSelectElement>(null)
	})

	const [immediateAttentionInput, setImmediateAttentionInput] =
		useState<RequestInputs>({
			name: 'immediateAttentionInput',
			value: '',
			valueKey: '',
			label:
				pageAssets?.request_form_TrafficLightAndPedestrianLight_roadUsersSecurity_label,
			labelKey:
				'request_form_TrafficLightAndPedestrianLight_roadUsersSecurity_label',
			required: false
		})
	const [immediateAttention, setImmediateAttention] = useState<RequestInputs>({
		name: 'immediateAttention',
		value: '',
		valueKey: '',
		label: '',
		labelKey: '',
		required: false
	})

	const lightingInterventionInputList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label:
				pageAssets?.request_form_TrafficLightAndPedestrianLight_wishList_traffic_light,
			value: REQUEST_CONFIGS_KEYS.lightingIntervention.addition,
			key: trafficLightAndPedestrianLightKeys.wishTrafficLight
		},
		{
			label:
				pageAssets?.request_form_TrafficLightAndPedestrianLight_wishList_traffic_light_repaired,
			value: REQUEST_CONFIGS_KEYS.lightingIntervention.repair,
			key: trafficLightAndPedestrianLightKeys.wishTrafficLightRepair
		},
		{
			label:
				pageAssets?.request_form_TrafficLightAndPedestrianLight_wishList_traffic_light_modified,
			value: REQUEST_CONFIGS_KEYS.lightingIntervention.modification,
			key: trafficLightAndPedestrianLightKeys.wishTrafficLightModified
		}
	]

	const pedestrianLightInterventionInputList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label:
				pageAssets?.request_form_TrafficLightAndPedestrianLight_wishList_traffic_light_pedestrian,
			value: REQUEST_CONFIGS_KEYS.lightingIntervention.addition,
			key: trafficLightAndPedestrianLightKeys.wishTrafficLightPedestrian
		},
		{
			label:
				pageAssets?.request_form_TrafficLightAndPedestrianLight_wishList_traffic_light_repaired_pedestrian,
			value: REQUEST_CONFIGS_KEYS.lightingIntervention.repair,
			key: trafficLightAndPedestrianLightKeys.wishTrafficLightRepairPedestrian
		},
		{
			label:
				pageAssets?.request_form_TrafficLightAndPedestrianLight_wishList_traffic_light_modified_pedestrian,
			value: REQUEST_CONFIGS_KEYS.lightingIntervention.modification,
			key: trafficLightAndPedestrianLightKeys.wishTrafficLightModifiedPedestrian
		}
	]

	const onSelectRequestTypeInput = (value: string, valueKey: string) => {
		setRequestTypeInput({ ...requestTypeInput, value, valueKey })
		onFixError(UpdateRequestInputsErrorsArray(errors, requestTypeInput.name))

		const requestSelected: Request | undefined = requests.find(
			(request) => request.requestId === value
		)
		let title: string = ''

		if (value !== '' && requestSelected) {
			title = requestSelected?.title
		}

		if (value === '') {
			setInformationKey('')
		}

		dispatch(
			populateRequestModelState({
				title,
				typeId: value
			})
		)
	}

	const onSelectLightingIntervention = (value: string, valueKey: string) => {
		if (value !== '') {
			setInformationKey(valueKey)
		} else {
			setInformationKey('')
		}

		setLightingInterventionInput({
			...lightingInterventionInput,
			value,
			valueKey
		})
		onFixError(
			UpdateRequestInputsErrorsArray(errors, lightingInterventionInput.name)
		)
	}

	const onSelectPedestrianLightIntervention = (
		value: string,
		valueKey: string
	) => {
		if (value !== '') {
			setInformationKey(valueKey)
		} else {
			setInformationKey('')
		}
		setPedestrianLightInterventionInput({
			...pedestrianLightInterventionInput,
			value,
			valueKey
		})
		onFixError(
			UpdateRequestInputsErrorsArray(
				errors,
				pedestrianLightInterventionInput.name
			)
		)
	}

	const onSelectImmediateAttentionInput = (value: string, valueKey: string) => {
		setImmediateAttentionInput({ ...immediateAttentionInput, value, valueKey })

		if (value === radioSelectionYesNo[0].value) {
			setImmediateAttention({
				...immediateAttention,
				value: 'true',
				required: true
			})
			return
		}

		setImmediateAttention({ ...immediateAttention, value: '', required: false })
	}

	useEffect(() => {
		const list: SelectList[] = filterMultipleRequests(
			config.request.create.TrafficLightAndPedestrianLight
				.requestTypeListIncludes
		)

		setRequestTypeList(list)
	}, [])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, requestTypeInput))

		if (
			requestTypeInput.valueKey ==
			config.request.create.TrafficLightAndPedestrianLight.trafficLight
		) {
			setLightingInterventionInput((oldObject) => ({
				...oldObject,
				required: true
			}))
			setPedestrianLightInterventionInput((oldObject) => ({
				...oldObject,
				value: '',
				required: false
			}))
			setShowSecondQuestion(true)
			setShowThirdQuestion(false)
		} else if (
			requestTypeInput.valueKey ==
			config.request.create.TrafficLightAndPedestrianLight.pedestrianLight
		) {
			setPedestrianLightInterventionInput((oldObject) => ({
				...oldObject,
				required: true
			}))
			setLightingInterventionInput((oldObject) => ({
				...oldObject,
				value: '',
				required: false
			}))
			setShowThirdQuestion(true)
			setShowSecondQuestion(false)
		} else {
			setLightingInterventionInput((oldObject) => ({
				...oldObject,
				required: false
			}))
			setPedestrianLightInterventionInput((oldObject) => ({
				...oldObject,
				required: false
			}))
			setShowThirdQuestion(false)
			setShowSecondQuestion(false)
		}
	}, [requestTypeInput])

	useEffect(() => {
		if (
			requestTypeInput.valueKey ==
			config.request.create.TrafficLightAndPedestrianLight.trafficLight
		) {
			onInputsChange(
				UpdateRequestInputsArray(inputs, lightingInterventionInput)
			)
		} else {
			onInputsChange(
				UpdateRequestInputsArray(inputs, pedestrianLightInterventionInput)
			)
		}

		if (
			informationKey === trafficLightAndPedestrianLightKeys.wishTrafficLight ||
			informationKey ===
				trafficLightAndPedestrianLightKeys.wishTrafficLightPedestrian
		) {
			setShowAddLightInfo(true)
			setShowModifyLightInfo(false)
		} else if (
			informationKey ===
				trafficLightAndPedestrianLightKeys.wishTrafficLightModified ||
			informationKey ===
				trafficLightAndPedestrianLightKeys.wishTrafficLightRepair ||
			informationKey ===
				trafficLightAndPedestrianLightKeys.wishTrafficLightModifiedPedestrian ||
			informationKey ===
				trafficLightAndPedestrianLightKeys.wishTrafficLightRepairPedestrian
		) {
			setShowModifyLightInfo(true)
			setShowAddLightInfo(false)
		} else {
			setShowModifyLightInfo(false)
			setShowAddLightInfo(false)
		}
	}, [informationKey])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, immediateAttentionInput))
	}, [immediateAttentionInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, immediateAttention))
	}, [immediateAttention])

	return (
		<>
			<SelectInput
				id={requestTypeInput.name}
				label={requestTypeInput.label}
				value={requestTypeInput.value}
				list={requestTypeList}
				required
				onChange={onSelectRequestTypeInput}
				hasError={errors.includes(requestTypeInput.name)}
				ref={requestTypeInput.ref as RefObject<HTMLSelectElement>}
			/>

			{showSecondQuestion && (
				<SelectInput
					id={lightingInterventionInput.name}
					label={lightingInterventionInput.label}
					value={lightingInterventionInput.value}
					list={lightingInterventionInputList}
					required
					onChange={onSelectLightingIntervention}
					hasError={errors.includes(lightingInterventionInput.name)}
					ref={lightingInterventionInput.ref as RefObject<HTMLSelectElement>}
				/>
			)}
			{showThirdQuestion && (
				<SelectInput
					id={pedestrianLightInterventionInput.name}
					label={pedestrianLightInterventionInput.label}
					value={pedestrianLightInterventionInput.value}
					list={pedestrianLightInterventionInputList}
					required
					onChange={onSelectPedestrianLightIntervention}
					hasError={errors.includes(pedestrianLightInterventionInput.name)}
					ref={
						pedestrianLightInterventionInput.ref as RefObject<HTMLSelectElement>
					}
				/>
			)}

			{showAddLightInfo &&
				displayWarningSection(
					pageAssets?.request_form_TrafficLightAndPedestrianLight_wishList_traffic_light_info
				)}

			{showModifyLightInfo &&
				displayWarningSection(
					pageAssets?.request_form_TrafficLightAndPedestrianLight_wishList_modify_traffic_light_info
				)}

			<RadioInput
				name={immediateAttentionInput.name}
				label={immediateAttentionInput.label}
				value={immediateAttentionInput.value}
				list={radioSelectionYesNo}
				required={immediateAttentionInput.required}
				onChange={onSelectImmediateAttentionInput}
			/>
		</>
	)
}

export default TrafficLightAndPedestrianLight
