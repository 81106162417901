import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import { RequestFormProps, RequestInputs, SelectList } from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import SelectInput from '@components/requestForms/inputs/select.inputs'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'

const SpeedAndSignalingForm: FC<RequestFormProps> = ({
	inputs,
	errors,
	onInputsChange,
	onFixError
}) => {
	const {
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputsErrorsArray,
		displayWarningSection
	} = requestForm()

	const [intervantionType, setIntervantionType] = useState<RequestInputs>({
		name: 'intervantionType',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_speedAndSignaling_intervantionType_label,
		labelKey: 'request_form_speedAndSignaling_intervantionType_label',
		required: true,
		ref: useRef<HTMLSelectElement>(null)
	})

	const interventionTypeList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.request_form_select_police_surveillance,
			value: REQUEST_CONFIGS_KEYS.intervention.police,
			key: 'request_form_select_police_surveillance'
		},
		{
			label: pageAssets?.request_form_select_change_in_signage,
			value: REQUEST_CONFIGS_KEYS.intervention.signage,
			key: 'request_form_select_change_in_signage'
		}
	]

	const onChangeIntervantionType = (value: string, valueKey: string) => {
		setIntervantionType({ ...intervantionType, value, valueKey })
		onFixError(UpdateRequestInputsErrorsArray(errors, intervantionType.name))
	}

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, intervantionType))
	}, [intervantionType])

	return (
		<>
			<SelectInput
				id={intervantionType.name}
				label={intervantionType.label}
				list={interventionTypeList}
				value={intervantionType.value}
				required
				onChange={onChangeIntervantionType}
				hasError={errors.includes(intervantionType.name)}
				ref={intervantionType.ref as RefObject<HTMLSelectElement>}
			/>

			{intervantionType.value === REQUEST_CONFIGS_KEYS.intervention.police &&
				displayWarningSection(
					pageAssets?.request_form_speed_signaling_info_text_surveillance_label
				)}

			{intervantionType.value === REQUEST_CONFIGS_KEYS.intervention.signage &&
				displayWarningSection(
					pageAssets?.request_form_speed_signaling_info_text_modification_label
				)}
		</>
	)
}

export default SpeedAndSignalingForm
