import React, {
	FC,
	RefObject,
	useContext,
	useEffect,
	useRef,
	useState
} from 'react'
import {
	RadioList,
	RequestFormProps,
	RequestInputs,
	SelectList
} from '@utils/request'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import SelectInput from '@components/requestForms/inputs/select.inputs'
import requestForm from '@components/requestForms/request.form'
import RadioInput from '@components/requestForms/inputs/radio.input'
import config from '@utils/config'
import { useAppDispatch } from '@services/store'
import { populateRequestModelState } from '@services/store/request'
import { Request } from 'typings/shared'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'

type PropertyCollectionHousing = {
	propertyType: string
	collectionSector: string
	collectionHousing: string
}

const MaterialsOrWasteNotCollectedForm: FC<RequestFormProps> = ({
	inputs,
	errors,
	onInputsChange,
	onFixError
}) => {
	const { pageData } = useContext<AppContextProps>(AppStateContext)
	const {
		pageAssets,
		radioSelectionYesNo,
		radioSelectionYesNoDoNotKnow,
		UpdateRequestInputsArray,
		UpdateRequestInputsErrorsArray,
		displayWarningSection,
		filterMultipleRequests
	} = requestForm()

	const dispatch = useAppDispatch()

	const bulkyItems: string =
		config.request.create.materialsOrWasteNotCollected.bulkyItems

	const [isBulkyItemsSelected, setIsBulkyItemsSelected] =
		useState<boolean>(false)
	const [requestTypeList, setRequestTypeList] = useState<SelectList[]>([])
	const [propertyCollectionHousingList, setPropertyCollectionHousingList] =
		useState<SelectList[]>([])
	const [notificationRequestFormText, setNotificationRequestFormText] =
		useState<string>('')
	const [notificationRequestTypeText, setNotificationRequestTypeText] =
		useState<string>('')
	const [requestTypeInput, setRequestTypeInput] = useState<RequestInputs>({
		name: 'requestType',
		value: '',
		label:
			pageAssets?.request_form_materialsOrWasteNotCollected_requestType_label,
		labelKey: 'request_form_materialsOrWasteNotCollected_requestType_label',
		required: true,
		ref: useRef<HTMLSelectElement>(null)
	})
	const [collectionLocationInput, setCollectionLocationInput] =
		useState<RequestInputs>({
			name: 'collectionLocation',
			label:
				pageAssets?.request_form_materialsOrWasteNotCollected_collectionLocation_label,
			labelKey:
				'request_form_materialsOrWasteNotCollected_collectionLocation_label',
			value: '',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		})
	const [binProvidedCityInput, setBinProvidedCityInput] =
		useState<RequestInputs>({
			name: 'binProvided',
			label:
				pageAssets?.request_form_materialsOrWasteNotCollected_binProvided_label,
			labelKey: 'request_form_materialsOrWasteNotCollected_binProvided_label',
			value: '',
			required: false
		})
	const [binProvidedTypeInput, setBinProvidedTypeInput] =
		useState<RequestInputs>({
			name: 'binProvidedType',
			label:
				pageAssets?.request_form_materialsOrWasteNotCollected_binProvidedType_label,
			labelKey:
				'request_form_materialsOrWasteNotCollected_binProvidedType_label',
			value: '',
			required: false
		})
	const [propertyCollectionHousingInput, setPropertyCollectionHousingInput] =
		useState<RequestInputs>({
			name: 'propertyCollectionHousing',
			label:
				pageAssets?.request_form_materialsOrWasteNotCollected_propertyCollectionHousing_label,
			labelKey:
				'request_form_materialsOrWasteNotCollected_propertyCollectionHousing_label',
			value: '',
			valueKey: '',
			required: false,
			ref: useRef<HTMLSelectElement>(null)
		})
	const [propertyTypeInput, setPropertyTypeInput] = useState<RequestInputs>({
		name: 'propertyType',
		label: '',
		value: '',
		labelKey: '',
		required: false
	})
	const [collectionSectorInput, setCollectionSectorInput] =
		useState<RequestInputs>({
			name: 'collectionSector',
			label: '',
			value: '',
			labelKey: '',
			required: false
		})
	const [collectionHousingInput, setCollectionHousingInput] =
		useState<RequestInputs>({
			name: 'collectionHousing',
			label: '',
			value: '',
			labelKey: '',
			required: false
		})
	const [neighborhoodSituationInput, setNeighborhoodSituationInput] =
		useState<RequestInputs>({
			name: 'neighborhoodSituation',
			label:
				pageAssets?.request_form_materialsOrWasteNotCollected_neighborhoodSituation_label,
			value: '',
			labelKey:
				'request_form_materialsOrWasteNotCollected_neighborhoodSituation_label',
			required: false
		})
	const [situationRecurrentInput, setSituationRecurrentInput] =
		useState<RequestInputs>({
			name: 'situationRecurrent',
			label:
				pageAssets?.request_form_materialsOrWasteNotCollected_situationRecurrent_label,
			labelKey:
				'request_form_materialsOrWasteNotCollected_situationRecurrent_label',
			value: '',
			required: false
		})
	const [natureCollectingInput, setNatureCollectingInput] =
		useState<RequestInputs>({
			name: 'natureCollecting',
			label:
				pageAssets?.request_form_materialsOrWasteNotCollected_natureCollecting_label,
			labelKey:
				'request_form_materialsOrWasteNotCollected_natureCollecting_label',
			value: '',
			required: false,
			ref: useRef<HTMLSelectElement>(null)
		})

	const collectionLocationList: RadioList[] = [
		{
			label: pageAssets?.request_form_select_yes,
			value: REQUEST_CONFIGS_KEYS.yes,
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets?.select_collection_location_no,
			value: REQUEST_CONFIGS_KEYS.no,
			key: 'select_collection_location_no'
		}
	]

	const binProvidedTypeList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_binProvidedType_container,
			value: pageAssets?.select_binProvidedType_container,
			key: 'select_binProvidedType_container'
		},
		{
			label: pageAssets?.select_binProvidedType_moloch,
			value: pageAssets?.select_binProvidedType_moloch,
			key: 'select_binProvidedType_moloch'
		},
		{
			label: pageAssets?.request_form_select_others,
			value: pageAssets?.request_form_select_others,
			key: 'request_form_select_others'
		}
	]

	const neighborhoodSituationList: RadioList[] = [
		{
			label: pageAssets?.request_form_select_yes,
			value: pageAssets?.request_form_select_yes,
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets?.select_neighborhoodSituation_no,
			value: pageAssets?.select_neighborhoodSituation_no,
			key: 'select_neighborhoodSituation_no'
		},
		{
			label: pageAssets?.request_form_select_dont_know,
			value: pageAssets?.request_form_select_dont_know,
			key: 'request_form_select_dont_know'
		}
	]

	const natureCollectingList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_natureCollecting_appliances,
			value: REQUEST_CONFIGS_KEYS.natureCollecting.appliances,
			key: 'select_natureCollecting_appliances'
		},
		{
			label: pageAssets?.select_natureCollecting_electronic,
			value: REQUEST_CONFIGS_KEYS.natureCollecting.electronic,
			key: 'select_natureCollecting_electronic'
		},
		{
			label: pageAssets?.select_natureCollecting_waste,
			value: REQUEST_CONFIGS_KEYS.natureCollecting.waste,
			key: 'select_natureCollecting_waste'
		},
		{
			label: pageAssets?.select_natureCollecting_bulkyItems,
			value: REQUEST_CONFIGS_KEYS.natureCollecting.bulky,
			key: 'select_natureCollecting_bulkyItems'
		},
		{
			label: pageAssets?.select_natureCollecting_leaves,
			value: REQUEST_CONFIGS_KEYS.natureCollecting.leaves,
			key: 'select_natureCollecting_leaves'
		},
		{
			label: pageAssets?.select_natureCollecting_fir,
			value: REQUEST_CONFIGS_KEYS.natureCollecting.fir,
			key: 'select_natureCollecting_fir'
		}
	]

	const onSelectRequestTypeInput = (value: string, valueKey: string) => {
		setRequestTypeInput({ ...requestTypeInput, value, valueKey })
		setIsBulkyItemsSelected(valueKey === bulkyItems)
		setPropertyCollectionHousingList([])

		updateFieldsRequiredAndValuesOnSelectRequestType(
			valueKey === bulkyItems,
			value === ''
		)
		onFixError(UpdateRequestInputsErrorsArray(errors, requestTypeInput.name))

		const requestSelected: Request | undefined = pageData.requests.find(
			(request) => request.requestId === value
		)
		let title: string = ''

		if (value !== '' && requestSelected) {
			title = requestSelected.title
		}

		dispatch(
			populateRequestModelState({
				title,
				typeId: value
			})
		)
	}

	const onSelectCollectionLocationInput = (value: string, valueKey: string) => {
		setCollectionLocationInput({ ...collectionLocationInput, value, valueKey })
		setNeighborhoodSituationInput({
			...neighborhoodSituationInput,
			value: '',
			valueKey: ''
		})
		setSituationRecurrentInput({
			...situationRecurrentInput,
			value: '',
			valueKey: ''
		})

		updatePropertyCollectionHousingOnSelectCollectionLocation(value)
		onFixError(
			UpdateRequestInputsErrorsArray(errors, collectionLocationInput.name)
		)
	}

	const onSelectBinProvidedCityInput = (value: string, valueKey: string) => {
		setBinProvidedCityInput({ ...binProvidedCityInput, value, valueKey })
		setBinProvidedTypeInput({
			...binProvidedTypeInput,
			value: '',
			valueKey: ''
		})
	}

	const onSelectBinProvidedTypeInput = (value: string, valueKey: string) => {
		setBinProvidedTypeInput({ ...binProvidedTypeInput, value, valueKey })
	}

	const onSelectPropertyCollectionHousingInput = (
		value: string,
		valueKey: string
	) => {
		setPropertyCollectionHousingInput({
			...propertyCollectionHousingInput,
			value,
			valueKey
		})

		onUpdatePropertyCollectionHousingFields(value)
		onFixError(UpdateRequestInputsErrorsArray(errors, propertyTypeInput.name))
	}

	const onSelectNeighborhoodSituationInput = (
		value: string,
		valueKey: string
	) => {
		setNeighborhoodSituationInput({
			...neighborhoodSituationInput,
			value,
			valueKey
		})
	}

	const onSelectSituationRecurrentInput = (value: string, valueKey: string) => {
		setSituationRecurrentInput({ ...situationRecurrentInput, value, valueKey })
	}

	const onSelectNatureCollectingInput = (value: string, valueKey: string) => {
		setNatureCollectingInput({ ...natureCollectingInput, value, valueKey })
	}

	const updatePropertyCollectionHousingOnSelectCollectionLocation = (
		value: string
	) => {
		setPropertyCollectionHousingInput({
			...propertyCollectionHousingInput,
			value: '',
			valueKey: ''
		})

		if (value === REQUEST_CONFIGS_KEYS.yes) {
			setPropertyCollectionHousingList(propertyCollectionHousingYesList)

			return
		}

		setPropertyCollectionHousingList(propertyCollectionHousingNoList)
	}

	const onUpdatePropertyCollectionHousingFields = (value: string) => {
		if (value === '') {
			setPropertyTypeInput({ ...propertyTypeInput, value, valueKey: '' })
			setCollectionSectorInput({
				...collectionSectorInput,
				value,
				valueKey: ''
			})
			setCollectionHousingInput({
				...collectionHousingInput,
				value,
				valueKey: ''
			})

			return
		}

		const fields: PropertyCollectionHousing = JSON.parse(value)

		setPropertyTypeInput({ ...propertyTypeInput, value: fields.propertyType })
		setCollectionSectorInput({
			...collectionSectorInput,
			value: fields.collectionSector
		})
		setCollectionHousingInput({
			...collectionHousingInput,
			value: fields.collectionHousing
		})
	}

	const updateNotificationText = () => {
		if (requestTypeInput.value === '') {
			setNotificationRequestTypeText('')

			return
		}

		const labelKey: string = `${config.request.create.materialsOrWasteNotCollected.assestStrapiName}${requestTypeInput.valueKey}`

		setNotificationRequestTypeText(`${pageAssets[labelKey]}`)

		return
	}

	const updateFieldsRequiredAndValuesOnSelectRequestType = (
		IsBulkySelected: boolean,
		isValueEmpty: boolean
	) => {
		if (isValueEmpty) {
			updateNoBulkyItemsRequiredInputs(false)
			setNatureCollectingInput({
				...natureCollectingInput,
				value: '',
				valueKey: '',
				required: false
			})

			return
		}

		if (IsBulkySelected) {
			setNatureCollectingInput({
				...natureCollectingInput,
				value: '',
				valueKey: '',
				required: true
			})
			updateNoBulkyItemsRequiredInputs(false)

			return
		}

		setNatureCollectingInput({
			...natureCollectingInput,
			value: '',
			valueKey: '',
			required: false
		})
		updateNoBulkyItemsRequiredInputs(true)
	}

	const updateNoBulkyItemsRequiredInputs = (required: boolean) => {
		setCollectionLocationInput({
			...collectionLocationInput,
			value: '',
			valueKey: '',
			required
		})
		setPropertyCollectionHousingInput({
			...propertyCollectionHousingInput,
			value: '',
			valueKey: '',
			required
		})
		setPropertyTypeInput({
			...propertyTypeInput,
			value: '',
			valueKey: '',
			required
		})
		setCollectionSectorInput({
			...collectionSectorInput,
			value: '',
			valueKey: '',
			required
		})
		setCollectionHousingInput({
			...collectionHousingInput,
			value: '',
			valueKey: '',
			required
		})
	}

	const convertPropertyCollectionHousingToString = (
		propertyType: string,
		collectionSector: string,
		collectionHousing: string
	): string =>
		JSON.stringify({ propertyType, collectionSector, collectionHousing })

	const propertyCollectionHousingYesList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label:
				pageAssets?.select_propertyCollectionHousing_privateResidentialSevenLess,
			value: convertPropertyCollectionHousingToString(
				REQUEST_CONFIGS_KEYS.property.private,
				REQUEST_CONFIGS_KEYS.sector.residential,
				REQUEST_CONFIGS_KEYS.housing.sevenAndLess
			),
			key: 'select_propertyCollectionHousing_privateResidentialSevenLess'
		},
		{
			label:
				pageAssets?.select_propertyCollectionHousing_privateResidentialEightMore,
			value: convertPropertyCollectionHousingToString(
				REQUEST_CONFIGS_KEYS.property.private,
				REQUEST_CONFIGS_KEYS.sector.residential,
				REQUEST_CONFIGS_KEYS.housing.eightAndMore
			),
			key: 'select_propertyCollectionHousing_privateResidentialEightMore'
		},
		{
			label: pageAssets?.select_propertyCollectionHousing_privateIndustryOthers,
			value: convertPropertyCollectionHousingToString(
				REQUEST_CONFIGS_KEYS.property.private,
				REQUEST_CONFIGS_KEYS.sector.industry,
				REQUEST_CONFIGS_KEYS.housing.others
			),
			key: 'select_propertyCollectionHousing_privateIndustryOthers'
		},
		{
			label:
				pageAssets?.select_propertyCollectionHousing_privateInstitutionOthers,
			value: convertPropertyCollectionHousingToString(
				REQUEST_CONFIGS_KEYS.property.private,
				REQUEST_CONFIGS_KEYS.sector.institution,
				REQUEST_CONFIGS_KEYS.housing.others
			),
			key: 'select_propertyCollectionHousing_privateInstitutionOthers'
		}
	]

	const propertyCollectionHousingNoList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_propertyCollectionHousing_cityOthers,
			value: convertPropertyCollectionHousingToString(
				REQUEST_CONFIGS_KEYS.property.city,
				REQUEST_CONFIGS_KEYS.sector.city,
				REQUEST_CONFIGS_KEYS.housing.others
			),
			key: 'select_propertyCollectionHousing_cityOthers'
		},
		{
			label: pageAssets?.select_propertyCollectionHousing_cityInstitutionOthers,
			value: convertPropertyCollectionHousingToString(
				REQUEST_CONFIGS_KEYS.property.city,
				REQUEST_CONFIGS_KEYS.sector.institution,
				REQUEST_CONFIGS_KEYS.housing.others
			),
			key: 'select_propertyCollectionHousing_cityInstitutionOthers'
		}
	]

	const updateNotificationRequestFormText = () => {
		if (
			propertyCollectionHousingList.length ===
			propertyCollectionHousingYesList.length
		) {
			setNotificationRequestFormText(
				pageAssets?.request_form_materialsOrWaste_notification_propertyCollectionHousingYes_form
			)

			return
		}

		setNotificationRequestFormText(
			pageAssets?.request_form_materialsOrWaste_notification_form
		)
	}

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, requestTypeInput))

		updateNotificationText()
		updateNotificationRequestFormText()
	}, [requestTypeInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, collectionLocationInput))
	}, [collectionLocationInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, binProvidedCityInput))
	}, [binProvidedCityInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, propertyTypeInput))
	}, [propertyTypeInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, collectionSectorInput))
	}, [collectionSectorInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, collectionHousingInput))
	}, [collectionHousingInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, neighborhoodSituationInput))
	}, [neighborhoodSituationInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, situationRecurrentInput))
	}, [situationRecurrentInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, natureCollectingInput))
	}, [natureCollectingInput])

	useEffect(() => {
		updateNotificationRequestFormText()
	}, [propertyCollectionHousingList])

	useEffect(() => {
		onInputsChange(
			UpdateRequestInputsArray(inputs, propertyCollectionHousingInput)
		)
	}, [propertyCollectionHousingInput])

	useEffect(() => {
		const list: SelectList[] = filterMultipleRequests(
			config.request.create.materialsOrWasteNotCollected.requestTypeListIncludes
		)

		setRequestTypeList(list)
		setNotificationRequestFormText(
			pageAssets?.request_form_materialsOrWaste_notification_form
		)
	}, [])

	return (
		<>
			<SelectInput
				id={requestTypeInput.name}
				label={requestTypeInput.label}
				value={requestTypeInput.value}
				list={requestTypeList}
				required
				hasError={errors.includes(requestTypeInput.name)}
				onChange={onSelectRequestTypeInput}
				ref={requestTypeInput.ref as RefObject<HTMLSelectElement>}
			/>

			{notificationRequestTypeText !== '' &&
				displayWarningSection(notificationRequestTypeText)}

			{requestTypeInput.value !== '' && !isBulkyItemsSelected && (
				<>
					<RadioInput
						label={collectionLocationInput.label}
						value={collectionLocationInput.value}
						list={collectionLocationList}
						name={collectionLocationInput.name}
						required={collectionLocationInput.required}
						hasError={errors.includes(collectionLocationInput.name)}
						onChange={onSelectCollectionLocationInput}
						ref={collectionLocationInput.ref as RefObject<HTMLInputElement>}
					/>

					<RadioInput
						label={binProvidedCityInput.label}
						value={binProvidedCityInput.value}
						list={radioSelectionYesNo}
						name={binProvidedCityInput.name}
						onChange={onSelectBinProvidedCityInput}
					/>

					{binProvidedCityInput.value ===
						pageAssets?.request_form_select_no && (
						<SelectInput
							id={binProvidedTypeInput.name}
							label={binProvidedTypeInput.label}
							value={binProvidedTypeInput.value}
							onChange={onSelectBinProvidedTypeInput}
							list={binProvidedTypeList}
						/>
					)}

					{propertyCollectionHousingList.length > 0 && (
						<>
							<SelectInput
								id={propertyCollectionHousingInput.name}
								label={propertyCollectionHousingInput.label}
								value={propertyCollectionHousingInput.value}
								list={propertyCollectionHousingList}
								required={propertyCollectionHousingInput.required}
								onChange={onSelectPropertyCollectionHousingInput}
								hasError={errors.includes(propertyTypeInput.name)}
								ref={
									propertyCollectionHousingInput.ref as RefObject<HTMLSelectElement>
								}
							/>
						</>
					)}

					{collectionLocationInput.value === REQUEST_CONFIGS_KEYS.yes && (
						<>
							<RadioInput
								label={neighborhoodSituationInput.label}
								value={neighborhoodSituationInput.value}
								list={neighborhoodSituationList}
								name={neighborhoodSituationInput.name}
								onChange={onSelectNeighborhoodSituationInput}
							/>
							<RadioInput
								label={situationRecurrentInput.label}
								value={situationRecurrentInput.value}
								list={radioSelectionYesNoDoNotKnow}
								name={situationRecurrentInput.name}
								onChange={onSelectSituationRecurrentInput}
							/>
						</>
					)}
				</>
			)}

			{requestTypeInput.value !== '' && isBulkyItemsSelected && (
				<>
					<SelectInput
						id={natureCollectingInput.name}
						label={natureCollectingInput.label}
						value={natureCollectingInput.value}
						list={natureCollectingList}
						required={natureCollectingInput.required}
						onChange={onSelectNatureCollectingInput}
						ref={natureCollectingInput.ref as RefObject<HTMLSelectElement>}
					/>
				</>
			)}

			{notificationRequestFormText !== '' &&
				displayWarningSection(notificationRequestFormText)}
		</>
	)
}

export default MaterialsOrWasteNotCollectedForm
