import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import { RequestFormProps, RequestInputs, SelectList } from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import SelectInput from '@components/requestForms/inputs/select.inputs'
import TextInput from '@components/requestForms/inputs/text.inputs'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'
import RadioInput from './inputs/radio.input'
import { Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import { makeClasses } from '@utils/styles'
import { formatStrapiText } from '@utils/methods'
import { Icon } from '@components/ui/Icon'
import { closeIcon } from '@images/icons'

const TreeDonationForm: FC<RequestFormProps> = ({
	inputs,
	errors,
	onInputsChange,
	onFixError
}) => {
	const {
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputsErrorsArray,
		displayWarningSection,
		radioSelectionYesNo
	} = requestForm()

	type Classes = {
		button: string
		h4: string
		rowSection: string
		rowRemoveContainer: string
		closeIcon: string
		description: string
	}

	const classes: Classes = makeClasses({
		button: {
			display: 'inline-block',
			padding: '10px 20px',
			fontSize: '13px',
			fontWeight: '500',
			color: Colors.white,
			lineHeight: '1em',
			borderRadius: '23px',
			transition: 'all 0.35s ease',
			MsTransition: 'all 0.35s ease',
			background: Colors.secondary,
			border: 'none',
			cursor: 'pointer',
			'&:hover': {
				background: Colors.darkBlue2,
				borderColor: Colors.darkBlue2,
				color: Colors.white
			}
		},
		h4: {
			fontSize: '22px',
			lineHeight: '26px',
			margin: '0 0 15px 0',
			paddingTop: '20px',
			color: Colors.darkBlue1,
			[Breakpoints.maxWidth('sm')]: {
				fontSize: '20px'
			}
		},
		rowSection: {
			display: 'grid',
			alignItems: 'end',
			gridTemplateColumns: '95%4%',
			columnGap: '1%',

			'& > div': {
				marginBottom: '15px'
			}
		},

		rowRemoveContainer: {
			display: 'flex',
			'& > span': {
				marginBottom: '9px'
			}
		},

		closeIcon: {
			display: 'inline-flex',
			height: '30px',
			marginLeft: '-3px',
			cursor: 'pointer',
			alignSelf: 'flex-start',
			marginBottom: '7px',
			img: {
				height: '100%'
			},

			'& span': {
				display: 'none'
			},

			[Breakpoints.maxWidth('sm')]: {
				marginTop: '5px',
				height: '27px'
				// '& span': {
				// 	display: 'inline',
				// 	color: '#0054a6',
				// 	textDecoration: 'underline',
				// 	lineHeight: '27px'
				// }
			}
		},

		description: {
			marginBottom: '35px !important'
		}
	})

	const [showSecondQuestion, setShowSecondQuestion] = useState<boolean>(false)

	const [isOwnerAdressInput, setIsOwnerAdressInput] = useState<RequestInputs>({
		name: 'isOwnerAdress',
		value: radioSelectionYesNo[0].value,
		label: pageAssets?.request_form_treeDonation_isOwnerAdress_label,
		labelKey: 'request_form_treeDonation_isOwnerAdress_label',
		required: true,
		ref: useRef<HTMLSelectElement>(null)
	})

	const [ownerStatusInput, setOwnerStatusInput] = useState<RequestInputs>({
		name: 'ownerStatus',
		value: '',
		label: pageAssets?.request_form_treeDonation_ownerStatus_label,
		labelKey: 'request_form_treeDonation_ownerStatus_label',
		required: false,
		ref: useRef<HTMLSelectElement>(null)
	})

	const ownerStatusList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_ownerStatus_tenant,
			value: pageAssets?.select_ownerStatus_tenant,
			key: 'select_ownerStatus_tenant'
		},
		{
			label: pageAssets?.select_ownerStatus_coOwner,
			value: pageAssets?.select_ownerStatus_coOwner,
			key: 'select_ownerStatus_coOwner'
		},
		{
			label: pageAssets?.select_ownerStatus_coOwnerSyndicate,
			value: pageAssets?.select_ownerStatus_coOwnerSyndicate,
			key: 'select_ownerStatus_coOwnerSyndicate'
		},
		{
			label: pageAssets?.select_ownerStatus_buildingManager,
			value: pageAssets?.select_ownerStatus_buildingManager,
			key: 'select_ownerStatus_buildingManager'
		},
		{
			label: pageAssets?.select_ownerStatus_other,
			value: pageAssets?.select_ownerStatus_other,
			key: 'select_ownerStatus_other'
		}
	]

	const [treeList, setTreeList] = useState<string[]>([''])

	// a fake RequestInputs to put treeList data in
	// value will be encoded as JSON
	const [treeListInput, setTreeListInput] = useState<RequestInputs>({
		name: 'treeList',
		label: pageAssets?.request_form_treeDonation_location_title,
		labelKey: 'request_form_treeDonation_location_title',
		value: JSON.stringify(treeList),
		required: false,
		ref: useRef<HTMLSelectElement>(null)
	})

	useEffect(() => {
		const programList: RequestInputs = {
			name: 'programList',
			value: parseInt(REQUEST_CONFIGS_KEYS.treeDonationList),
			valueKey: 'request_form_treeDonation_programList',
			label: '',
			labelKey: '',
			required: true
		}
		onInputsChange(UpdateRequestInputsArray(inputs, programList))
	}, [])

	const treeLocationList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_plantedTreeLocation_frontYard,
			value: pageAssets?.select_plantedTreeLocation_frontYard,
			key: 'select_plantedTreeLocation_frontYard'
		},
		{
			label: pageAssets?.select_plantedTreeLocation_backYard,
			value: pageAssets?.select_plantedTreeLocation_backYard,
			key: 'select_plantedTreeLocation_backYard'
		},
		{
			label: pageAssets?.select_plantedTreeLocation_sideYard,
			value: pageAssets?.select_plantedTreeLocation_sideYard,
			key: 'select_plantedTreeLocation_sideYard'
		}
	]

	const onSelectIsOwnerAdressInput = (value: string, valueKey: string) => {
		setIsOwnerAdressInput({ ...isOwnerAdressInput, value, valueKey })

		if (value == radioSelectionYesNo[1].value) {
			setShowSecondQuestion(true)
			setOwnerStatusInput({ ...ownerStatusInput, required: true })
		} else {
			setShowSecondQuestion(false)
			setOwnerStatusInput({ ...ownerStatusInput, required: false })
		}

		onFixError(
			errors.filter(
				(error) =>
					![isOwnerAdressInput.name, ownerStatusInput.name].includes(error)
			)
		)
	}

	const onChangeOwnerStatusInput = (value: string) => {
		setOwnerStatusInput({ ...ownerStatusInput, value })
		onFixError(UpdateRequestInputsErrorsArray(errors, ownerStatusInput.name))
	}

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, isOwnerAdressInput))
	}, [isOwnerAdressInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, ownerStatusInput))
	}, [ownerStatusInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, treeListInput))
	}, [treeListInput])

	const onSetTreeCurrentInput = (value: string, index) => {
		let itemList = JSON.parse(JSON.stringify(treeList))

		itemList[index] = value

		setTreeList(itemList)

		let itemTreeList = JSON.parse(treeListInput.value)

		itemTreeList[index] =
			value !== ''
				? pageAssets?.request_form_treePlanting_tree +
				  ' ' +
				  (index + 1) +
				  ' : ' +
				  value
				: ''

		setTreeListInput({
			...treeListInput,
			value: JSON.stringify(itemTreeList)
		})

		onFixError(updateTreeErrorsArray(itemList))
	}

	const updateTreeErrorsArray = (itemList: string[]): string[] => {
		let errorArray: string[] = []

		errors?.forEach((error) => {
			if (error.includes('treeLocation')) {
				itemList.forEach((tree, row) => {
					if (tree == '' && error == `treeLocation${row}`) {
						errorArray.push(error)
					}
				})
			} else {
				errorArray.push(error)
			}
		})

		return errorArray
	}

	const removeTreeRow = (row: number) => {
		let itemList = [...treeList]

		itemList.splice(row, 1)

		setTreeList(itemList)

		let itemTreeList = JSON.parse(treeListInput.value)

		itemTreeList.splice(row, 1)

		setTreeListInput({
			...treeListInput,
			value: JSON.stringify(itemTreeList)
		})

		let itemListLength = itemList.length

		onFixError(
			errors.filter(
				(error) =>
					![`treeLocation${row}`, `treeLocation${itemListLength}`].includes(
						error
					)
			)
		)
	}

	const addTreeRow = () => {
		let itemList = JSON.parse(JSON.stringify(treeList))

		itemList.push('')

		setTreeList(itemList)

		let itemTreeList = JSON.parse(treeListInput.value)

		itemTreeList.push('')

		setTreeListInput({
			...treeListInput,
			value: JSON.stringify(itemTreeList)
		})

		onFixError(updateTreeErrorsArray(itemList))
	}

	const renderTreeRow = (tree: string, index: number) => {
		return (
			<>
				<div className={classes.rowSection}>
					<SelectInput
						id={`treeLocation${index}`}
						label={`${pageAssets?.request_form_treeDonation_location_label} #${
							index + 1
						}`}
						value={tree}
						list={treeLocationList}
						onChange={(e) => onSetTreeCurrentInput(e, index)}
						hasError={errors.includes(`treeLocation${index}`)}
						required
					/>

					<div className={classes.rowRemoveContainer}>
						{treeList.length > 1 && (
							<span
								className={classes.closeIcon}
								onClick={() => removeTreeRow(index)}
							>
								<Icon src={closeIcon} />
								<span>{pageAssets?.request_form_treePlanting_remove_tree}</span>
							</span>
						)}
					</div>
				</div>
			</>
		)
	}

	return (
		<>
			<RadioInput
				name={isOwnerAdressInput.name}
				label={isOwnerAdressInput.label}
				value={isOwnerAdressInput.value}
				list={radioSelectionYesNo}
				required={isOwnerAdressInput.required}
				onChange={onSelectIsOwnerAdressInput}
				hasError={errors.includes(isOwnerAdressInput.name)}
				hasBackgroundWrapper={true}
			/>

			{showSecondQuestion && (
				<SelectInput
					id={ownerStatusInput.name}
					label={ownerStatusInput.label}
					value={ownerStatusInput.value}
					list={ownerStatusList}
					onChange={onChangeOwnerStatusInput}
					hasError={errors.includes(ownerStatusInput.name)}
					required
				/>
			)}

			<h4 className={classes.h4}>
				{pageAssets?.request_form_treeDonation_location_title}
			</h4>

			<p className={classes.description}>
				{formatStrapiText(
					pageAssets?.request_form_treeDonation_location_description
				)}
			</p>

			{/* /////////////////   Tree List inputs  //////////////// */}
			{treeList.map((tree, index) => {
				return renderTreeRow(tree, index)
			})}

			{/* /////////////////  Add Button  //////////////// */}
			{treeList.length < 10 && (
				<a
					style={{ marginBottom: '30px' }}
					onClick={() => addTreeRow()}
					className={classes.button}
				>
					{pageAssets?.request_form_treePlanting_add_tree}
				</a>
			)}

			{displayWarningSection(
				pageAssets?.request_form_treeDonation_notification
			)}
		</>
	)
}

export default TreeDonationForm
