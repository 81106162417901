import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import { RequestFormProps, RequestInputs, SelectList } from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import RadioInput from '@components/requestForms/inputs/radio.input'
import SelectInput from '@components/requestForms/inputs/select.inputs'
import { useAppDispatch } from '@services/store'
import { populateRequestConfigsState } from '@services/store/request'

const EvaluateTreeForm: FC<RequestFormProps> = ({
	inputs,
	errors,
	onInputsChange,
	onFixError
}) => {
	const {
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputsErrorsArray,
		radioListYesNo,
		radioListPropertyType,
		radioSelectionYesNoDoNotKnow
	} = requestForm()

	const dispatch = useAppDispatch()

	const selectInterventionType: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.request_form_select_plant,
			value: pageAssets?.request_form_select_plant,
			key: 'request_form_select_plant'
		},
		{
			label: pageAssets?.request_form_select_tear_down,
			value: pageAssets?.request_form_select_tear_down,
			key: 'request_form_select_tear_down'
		},
		{
			label: pageAssets?.request_form_select_prune,
			value: pageAssets?.request_form_select_prune,
			key: 'request_form_select_prune'
		},
		{
			label: pageAssets?.request_form_select_assess,
			value: pageAssets?.request_form_select_assess,
			key: 'request_form_select_assess'
		}
	]

	const [propertyInput, setPropertyInput] = useState<RequestInputs>({
		name: 'propertyType',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_evaluateTree_propertyType_label,
		labelKey: 'request_form_evaluateTree_propertyType_label',
		required: true,
		ref: useRef<HTMLInputElement>(null)
	})

	const [watersideInput, setWatersideInput] = useState<RequestInputs>({
		name: 'waterside',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_evaluateTree_waterside_label,
		labelKey: 'request_form_evaluateTree_waterside_label',
		required: true,
		ref: useRef<HTMLInputElement>(null)
	})

	const [dangerousTreeInput, setDangerousTreeInput] = useState<RequestInputs>({
		name: 'dangerousTree',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_evaluateTree_dangerousTree_label,
		labelKey: 'request_form_evaluateTree_dangerousTree_label',
		required: true,
		ref: useRef<HTMLInputElement>(null)
	})

	const [interventionTypeInput, setInterventionTypeInput] =
		useState<RequestInputs>({
			name: 'interventionType',
			value: '',
			valueKey: '',
			label: pageAssets?.request_form_evaluateTree_interventionType_label,
			labelKey: 'request_form_evaluateTree_interventionType_label',
			required: false
		})

	const [ashTreeInput, setAshTreeInput] = useState<RequestInputs>({
		name: 'ashTree',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_evaluateTree_ashTree_label,
		labelKey: 'request_form_evaluateTree_ashTree_label',
		required: false
	})

	const [accessibleTreeInput, setAccessibleTreeInput] = useState<RequestInputs>(
		{
			name: 'accessibleTree',
			value: '',
			valueKey: '',
			label: pageAssets?.request_form_evaluateTree_accessibleTree_label,
			labelKey: 'request_form_evaluateTree_accessibleTree_label',
			required: false
		}
	)

	const [workLocationInput, setWorkLocationInput] = useState<RequestInputs>({
		name: 'workLocation',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_evaluateTree_workLocation_label,
		labelKey: 'request_form_evaluateTree_workLocation_label',
		required: false
	})

	const [locatedPublicRoadInput, setLocatedPublicRoadInput] =
		useState<RequestInputs>({
			name: 'locatedPublicRoad',
			value: '',
			valueKey: '',
			label: pageAssets?.request_form_evaluateTree_locatedPublicRoad_label,
			labelKey: 'request_form_evaluateTree_locatedPublicRoad_label',
			required: false
		})

	const [authorizationCertificateInput, setAuthorizationCertificateInput] =
		useState<RequestInputs>({
			name: 'authorizationCertificate',
			value: '',
			valueKey: '',
			label:
				pageAssets?.request_form_evaluateTree_authorizationCertificate_label,
			labelKey: 'request_form_evaluateTree_authorizationCertificate_label',
			required: false
		})

	const onSelectPropertyType = (value: string, valueKey: string) => {
		setPropertyInput({ ...propertyInput, value, valueKey })
		onFixError(UpdateRequestInputsErrorsArray(errors, propertyInput.name))
	}

	const onSelectWaterside = (value: string, valueKey: string) => {
		setWatersideInput({ ...watersideInput, value, valueKey })
		onFixError(UpdateRequestInputsErrorsArray(errors, watersideInput.name))
	}

	const onSelectDangerousTree = (value: string, valueKey: string) => {
		setDangerousTreeInput({ ...dangerousTreeInput, value, valueKey })
		onFixError(UpdateRequestInputsErrorsArray(errors, dangerousTreeInput.name))

		dispatch(
			populateRequestConfigsState({
				immediateAttention: value === pageAssets?.request_form_select_yes_key
			})
		)
	}

	const onSelectInterventionType = (value: string, valueKey: string) => {
		setInterventionTypeInput({ ...interventionTypeInput, value, valueKey })
		clearValues(value)
	}

	const onSelectAshTree = (value: string, valueKey: string) =>
		setAshTreeInput({ ...ashTreeInput, value, valueKey })

	const onSelectAccessibleTree = (value: string, valueKey: string) =>
		setAccessibleTreeInput({ ...accessibleTreeInput, value, valueKey })

	const onSelectWorkLocation = (value: string, valueKey: string) =>
		setWorkLocationInput({ ...workLocationInput, value, valueKey })

	const onSelectLocatedPublicRoad = (value: string, valueKey: string) =>
		setLocatedPublicRoadInput({ ...locatedPublicRoadInput, value, valueKey })

	const onSelectAuthorizationCertificate = (value: string, valueKey: string) =>
		setAuthorizationCertificateInput({
			...authorizationCertificateInput,
			value,
			valueKey
		})

	const clearValues = (value: string) => {
		if (value === pageAssets?.request_form_select_plant) {
			setAshTreeInput({ ...ashTreeInput, value: '', valueKey: '' })
			setAccessibleTreeInput({
				...accessibleTreeInput,
				value: '',
				valueKey: ''
			})
			setWorkLocationInput({ ...workLocationInput, value: '', valueKey: '' })
		}

		if (value === pageAssets?.request_form_select_tear_down) {
			setAuthorizationCertificateInput({
				...authorizationCertificateInput,
				value: '',
				valueKey: ''
			})
		}
	}

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, propertyInput))
	}, [propertyInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, watersideInput))
	}, [watersideInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, dangerousTreeInput))
	}, [dangerousTreeInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, interventionTypeInput))
	}, [interventionTypeInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, ashTreeInput))
	}, [ashTreeInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, accessibleTreeInput))
	}, [accessibleTreeInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, workLocationInput))
	}, [workLocationInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, locatedPublicRoadInput))
	}, [locatedPublicRoadInput])

	useEffect(() => {
		onInputsChange(
			UpdateRequestInputsArray(inputs, authorizationCertificateInput)
		)
	}, [authorizationCertificateInput])

	return (
		<>
			<RadioInput
				name={propertyInput.name}
				label={propertyInput.label}
				list={radioListPropertyType}
				value={propertyInput.value}
				required
				hasError={errors.includes(propertyInput.name)}
				onChange={onSelectPropertyType}
				showInfo
				infoLabel={pageAssets?.request_form_evaluate_tree_property_type_popup}
				ref={propertyInput.ref as RefObject<HTMLInputElement>}
			/>

			<RadioInput
				name={watersideInput.name}
				label={watersideInput.label}
				list={radioListYesNo}
				value={watersideInput.value}
				required
				hasError={errors.includes(watersideInput.name)}
				onChange={onSelectWaterside}
				ref={watersideInput.ref as RefObject<HTMLInputElement>}
			/>

			<RadioInput
				name={dangerousTreeInput.name}
				label={dangerousTreeInput.label}
				list={radioListYesNo}
				value={dangerousTreeInput.value}
				required
				hasError={errors.includes(dangerousTreeInput.name)}
				onChange={onSelectDangerousTree}
				ref={dangerousTreeInput.ref as RefObject<HTMLInputElement>}
			/>

			<SelectInput
				id={interventionTypeInput.name}
				value={interventionTypeInput.value}
				label={interventionTypeInput.label}
				list={selectInterventionType}
				onChange={onSelectInterventionType}
			/>

			{interventionTypeInput.value &&
				interventionTypeInput.value !==
					pageAssets?.request_form_select_plant && (
					<>
						<RadioInput
							name={ashTreeInput.name}
							label={ashTreeInput.label}
							list={radioSelectionYesNoDoNotKnow}
							value={ashTreeInput.value}
							onChange={onSelectAshTree}
						/>

						<RadioInput
							name={accessibleTreeInput.name}
							label={accessibleTreeInput.label}
							list={radioSelectionYesNoDoNotKnow}
							value={accessibleTreeInput.value}
							onChange={onSelectAccessibleTree}
						/>

						<RadioInput
							name={workLocationInput.name}
							label={workLocationInput.label}
							list={radioSelectionYesNoDoNotKnow}
							value={workLocationInput.value}
							onChange={onSelectWorkLocation}
						/>
					</>
				)}

			<RadioInput
				name={locatedPublicRoadInput.name}
				label={locatedPublicRoadInput.label}
				list={radioSelectionYesNoDoNotKnow}
				value={locatedPublicRoadInput.value}
				onChange={onSelectLocatedPublicRoad}
			/>

			{interventionTypeInput.value &&
				interventionTypeInput.value ===
					pageAssets?.request_form_select_tear_down && (
					<RadioInput
						name={authorizationCertificateInput.name}
						label={authorizationCertificateInput.label}
						list={radioSelectionYesNoDoNotKnow}
						value={authorizationCertificateInput.value}
						onChange={onSelectAuthorizationCertificate}
					/>
				)}
		</>
	)
}

export default EvaluateTreeForm
