import React, {
	FC,
	RefObject,
	useEffect,
	useRef,
	useState,
	useContext
} from 'react'
import SectionTitle from '@components/ui/sectionTitle'
import MapForm from '@components/ui/map/MapForm'
import Text from '@components/ui/text'
import InformationBank from '@components/ui/sidebar/informationBank.sidebar'
import ProgressBar from '@components/ui/progressBar'
import Selection from '@components/requestForms/selection.form'
import TextAreaInput from '@components/requestForms/inputs/textarea.inputs'
import RequestConfirmation from '@components/ui/requestConfirmation'
import RequestFiles from '@components/ui/requestFiles'
import config from '@utils/config'
import RequestCore, { RequestFormType } from './create.core'
import { PageTitle } from '@components/configs/PageTitle'
import { joinClasses } from '@utils/styles'
import { RequestMetadata } from '@services/types'
import {
	AddressInputName,
	OthersRequestsId,
	RequestInputs,
	RequestNames,
	Steps
} from '@utils/request'
import { useAppDispatch } from '@services/store'
import { IRequestConfigsState } from '@services/store/request/type'
import {
	populateRequestModelState,
	populateRequestConfigsState,
	populateRequestLocationState
} from '@services/store/request'
import * as pageUtils from './__create.utils'
import { REQUEST_CONFIG_KEY_RELATION } from '@services/store/request/constant'
import RequestFailedModal from '@components/ui/requestFailedModal'
import CompactFooter from '@components/ui/footer/compactFooter'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import { LanguageEnum } from '@services/constants'

const CreateRequest: FC<pageUtils.Props> = ({ pageContext: { id } }) => {
	const {
		title,
		pageAssets,
		listArticles,
		request,
		steps,
		listSteps,
		currentStep,
		MAX_CHAR_DESC,
		loading,
		requestCreated,
		browser,
		files,
		inputs,
		coordinates,
		closeLink,
		isArticlesLoading,
		isOtherRequestPage,
		isAuthUserInLaval,
		address,
		apartment,
		errors,
		requestHasError,
		setErrors,
		setApartment,
		setAddress,
		setFiles,
		onSubmit,
		setDoSentPost,
		setInputs,
		setCoordinates,
		onAddErrorFromAddress,
		setRequestHasError,
		onResetRequestFailed
	}: RequestFormType = RequestCore(id)
	const dispatch = useAppDispatch()
	const { language } = useContext<AppContextProps>(AppStateContext)

	const [hasFileError, setHasFileError] = useState<boolean>(false)
	const [comments, setComments] = useState<RequestInputs>({
		name: 'details',
		value: '',
		label: '',
		labelKey: '',
		required: id === OthersRequestsId.name,
		ref: useRef<HTMLTextAreaElement>(null)
	})

	const processingDataAndValidation = () => {
		let details: string = ''
		let configs: IRequestConfigsState = {} as IRequestConfigsState
		const listErrors: string[] = []
		const metadata: RequestMetadata[] = []
		let isFirstErrorAlreadySet: boolean = false

		if (!coordinates) {
			listErrors.push(AddressInputName)
			isFirstErrorAlreadySet = true
		}

		inputs.forEach((input) => {
			// to validate the input value, contains an array of values
			if (input.name === 'treeList') {
				let treeList: string[] = JSON.parse(input.value)

				treeList?.forEach((element, index) => {
					if (element == '') {
						listErrors.push(`treeLocation${index}`)
					}
				})

				configs[input.name] = treeList
			}

			if (input.required && input.value === '') {
				listErrors.push(input.name)

				if (input.ref && !isFirstErrorAlreadySet) {
					input.ref.current?.focus()
					isFirstErrorAlreadySet = true
				}
			}

			// a special case for the tree Donation form to include all fields in details
			// the usual test here was :
			// if (!input.required && input.value !== '')
			if (
				(request?.name == RequestNames.treeDonation || !input.required) &&
				input.value !== '' &&
				input.name !== 'programList'
			) {
				// map the array to a formated string value, if it's the treeList input
				let mappedTreeList =
					input.name === 'treeList'
						? JSON.parse(input.value)
								.map((item) => item.replace(/"([^"]+)"/g, '$1'))
								.join(',\n')
						: ''

				let value =
					input.name === 'treeList' ? '\n' + mappedTreeList : input.value

				details += `${input.label} ${value}\r`

				let metaDataValue =
					input.name === 'treeList' ? mappedTreeList : input.value

				metadata.push({
					label: input.labelKey,
					value: `${input.valueKey ? input.valueKey : metaDataValue}`
				})
			}

			if (
				input.required &&
				input.value !== '' &&
				!config.request.create.fieldsToExcludeFromConfig.includes(input.name)
			) {
				let configsInputValue: number | string | boolean = input.value

				switch (REQUEST_CONFIG_KEY_RELATION[input.name]) {
					case 'number':
						configsInputValue = Number(configsInputValue)
						break
					case 'boolean':
						configsInputValue = configsInputValue.toLowerCase() === 'true'
						break
					default:
						break
				}

				configs[input.name] = configsInputValue

				// the test on request.name is for the special case of form treeDonation
				if (
					input.labelKey !== '' &&
					request?.name !== RequestNames.treeDonation
				) {
					metadata.push({
						label: input.labelKey,
						value: `${input.valueKey ? input.valueKey : input.value}`,
						required: true
					})
				}
			}
		})

		if (comments.required && comments.value === '') {
			listErrors.push(comments.name)

			if (comments.ref && !isFirstErrorAlreadySet) {
				comments.ref.current?.focus()
			}
		}

		metadata.push({
			label: comments.labelKey,
			value: comments.value,
			required: comments.required
		})

		dispatch(populateRequestConfigsState(configs))

		dispatch(
			populateRequestLocationState({
				address,
				apartment,
				coordinates: {
					longitude: coordinates?.longitude,
					latitude: coordinates?.latitude
				}
			})
		)

		const separator: string = comments.value === '' ? '' : '-\r'

		const description: string =
			details === ''
				? comments.value
				: `${details}${separator}${comments.value}`

		dispatch(
			populateRequestModelState({
				description,
				metadata,
				files: [...files]
			})
		)

		setErrors(listErrors)
		setDoSentPost(listErrors.length === 0)
	}

	const onFixError = (listErrors: string[]) => setErrors(listErrors)

	const onChangeComments = (value: string) => {
		setComments({ ...comments, value })

		if (comments.required) {
			onFixError(errors.filter((error) => error !== comments.name))
		}
	}

	const onChangeFileHasError = (hasError: boolean) => setHasFileError(hasError)

	useEffect(() => {
		if (coordinates && errors.length > 0) {
			setErrors(errors.filter((error: string) => error !== AddressInputName))
		}
	}, [coordinates])

	useEffect(() => {
		setComments({
			...comments,
			label: pageAssets?.page_requestInfo_inputDetails,
			labelKey: 'page_requestInfo_inputDetails'
		})
	}, [])

	const convertStepForProgessBar = (step: number) => {
		if (step === steps.form) {
			return 1
		} else {
			return 2
		}
	}

	const showFileSection = () => {
		return request?.name !== RequestNames.treeDonation
	}

	const showInformationBankSection = () => {
		return request?.name !== RequestNames.treeDonation
	}

	const getMaxCharsDesc = () => {
		if (request?.name !== RequestNames.othersRequests) {
			return MAX_CHAR_DESC
		} else {
			return config.request.input.textareaMaxCharForOtherRequest
		}
	}

	// const getLocationSectionTitle = () => {
	// 	switch (request?.name) {
	// 		case RequestNames.treeDonation:
	// 			return pageAssets?.request_form_plantation_adress
	// 		default:
	// 			return pageAssets?.page_requestInfo_location
	// 	}
	// }

	return (
		<div
			className={joinClasses([
				pageUtils.classes.root2,
				browser,
				language === LanguageEnum.EN ? pageUtils.classes.withCompactFooter : ''
			])}
		>
			<div className={pageUtils.classes.content}>
				<PageTitle title={title} />

				<div className={pageUtils.classes.sectionTitle}>
					<SectionTitle
						title={title}
						subTitle={request?.title}
						showBreadcrumbs={false}
						link={closeLink}
						classRoot={pageUtils.classes.sectionTitleRoot}
						showCharterInfo={false}
					/>
				</div>

				<div
					className={joinClasses([
						pageUtils.classes.informationContainer,
						currentStep === Steps.confirmation
							? pageUtils.classes.confirmationAlert
							: ''
					])}
				>
					<InformationBank
						isConfirmationPage={currentStep === Steps.confirmation}
						containerClass={pageUtils.classes.informationBank}
						isLoading={isArticlesLoading}
						articles={listArticles}
						isOtherRequestPage={isOtherRequestPage}
						requestWithoutInformationBank={showInformationBankSection()}
					/>
				</div>

				<div className={pageUtils.classes.progressBar}>
					<ProgressBar
						steps={listSteps}
						currentStep={convertStepForProgessBar(currentStep)}
					/>
				</div>
				{currentStep === steps.form && (
					<form className={pageUtils.classes.container}>
						<section
							className={pageUtils.classes.section3}
							style={{ marginTop: '0' }}
						>
							<h3 className={pageUtils.classes.h3}>
								<Text content={pageAssets?.page_requestInfo_location} />
							</h3>
							<div>
								<Text content={pageAssets?.page_requestInfo_location_text} />
								<MapForm
									addressPlaceholder={
										pageAssets?.page_requestInfo_inputAddressPlaceholder
									}
									hasError={errors.includes(AddressInputName)}
									addressRequired
									displayMap
									displayAptInput
									showButtonUserLocation={isAuthUserInLaval}
									onAddressInputChange={setAddress}
									onRetreiveCoordinates={setCoordinates}
									onApartmentInputChange={setApartment}
									onAddError={onAddErrorFromAddress}
								/>
							</div>
						</section>
						<section className={pageUtils.classes.section3}>
							<h3 className={pageUtils.classes.h3}>
								<Text content={pageAssets?.page_requestInfo_details} />
							</h3>
							<div>
								<Selection
									name={`${request?.name}`}
									inputs={inputs}
									errors={errors}
									onInputsChange={setInputs}
									onFixError={onFixError}
								/>

								<div>
									<TextAreaInput
										id={comments.name}
										label={comments.label}
										value={comments.value}
										maxCharacters={getMaxCharsDesc()}
										onChange={onChangeComments}
										required={comments.required}
										hasError={errors.includes(comments.name)}
										hasDescriptionLabel={`${getMaxCharsDesc()} ${
											pageAssets?.myRequest_characters_max
										}`}
										ref={comments.ref as RefObject<HTMLTextAreaElement>}
									/>
								</div>
							</div>
						</section>
						{showFileSection() && (
							<div className={joinClasses([pageUtils.classes.fileSection])}>
								<RequestFiles
									title={pageAssets?.page_requestInfo_enclosed}
									name={'request_file_field'}
									files={files}
									onSetFiles={(newFiles: Set<File>) => {
										const tmp: Set<File> = new Set<File>([...newFiles])
										setFiles(tmp)
									}}
									removeSectionMarginTop
									hasError={hasFileError}
									onSetHasError={onChangeFileHasError}
									maxFiles={10}
								>
									<span className={pageUtils.classes.inputDescLabel}>
										<Text
											content={pageAssets?.page_requestInfo_inputFileDesc}
										/>
									</span>
								</RequestFiles>
							</div>
						)}

						<RequestFailedModal
							requestHasError={requestHasError}
							setRequestHasError={setRequestHasError}
							onResetRequestFailed={onResetRequestFailed}
							closeLink={closeLink}
							setDoSentPost={setDoSentPost}
						/>

						<div className={pageUtils.classes.submitContainer}>
							<button
								type="button"
								className={joinClasses([
									pageUtils.classes.submitBtn,
									pageUtils.classes.btn,
									loading || errors.length > 0
										? pageUtils.classes.btnDisabled
										: ''
								])}
								onClick={onSubmit(
									processingDataAndValidation,
									errors.length > 0
								)}
							>
								{!loading && (
									<Text content={pageAssets?.page_requestInfo_inputSubmit} />
								)}
								{loading && (
									<div className={`${pageUtils.classes.loader} spin`} />
								)}
							</button>
							{loading && (
								<span className={pageUtils.classes.loadingMessage}>
									{
										pageAssets?.createRequest_page_wait_while_we_submit_your_request
									}
								</span>
							)}
						</div>
					</form>
				)}

				{currentStep === steps.confirmation && (
					<section
						className={joinClasses([
							pageUtils.classes.container,
							pageUtils.classes.noBackground,
							pageUtils.classes.confirmationContainer
						])}
					>
						<RequestConfirmation requestCreated={requestCreated} />
					</section>
				)}
			</div>
			{language === LanguageEnum.EN && <CompactFooter />}
		</div>
	)
}
export default CreateRequest
