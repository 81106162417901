import React, { FC, useEffect, useState } from 'react'
import requestForm from '@components/requestForms/request.form'
import Modal from '@components/ui/modal'
import RadioInput from '@components/requestForms/inputs/radio.input'
import {
	populateRequestConfigsState,
	resetRequestConfigsState
} from '@services/store/request'
import { RequestFormProps, RequestInputs } from '@utils/request'
import { Colors } from '@utils/css-variables'
import { makeClasses } from '@utils/styles'
import { useAppDispatch } from '@services/store'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'

const classes: { modalContent: string } = makeClasses({
	modalContent: {
		color: Colors.black2,
		fontSize: '16px',
		lineHeight: '26px',
		paddingBottom: '30px',
		textAlign: 'center'
	}
})

const PandemicEpidemicForm: FC<RequestFormProps> = ({
	inputs,
	onInputsChange
}) => {
	const {
		pageAssets,
		radioSelectionYesNo,
		UpdateRequestInputsArray,
		displayWarningSection
	} = requestForm()

	const dispatch = useAppDispatch()

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
	const [notificationText, setNotificationText] = useState<string>('')
	const [familySituationInput, setFamilySituationInput] =
		useState<RequestInputs>({
			name: 'familySituation',
			value: '',
			valueKey: '',
			label: pageAssets?.request_form_pandemicEpidemic_familySituation_label,
			labelKey: 'request_form_pandemicEpidemic_familySituation_label',
			required: false
		})

	const onSelectFamilySituationInput = (value: string, valueKey: string) => {
		setFamilySituationInput({ ...familySituationInput, value, valueKey })

		if (value === pageAssets?.request_form_select_yes) {
			setNotificationText(
				pageAssets?.request_form_pandemicEpidemic_notification_yes_text
			)

			return
		}

		setNotificationText(
			pageAssets?.request_form_pandemicEpidemic_notification_no_text
		)
	}

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, familySituationInput))
	}, [familySituationInput])

	useEffect(() => {
		dispatch(
			populateRequestConfigsState({
				emergencyType: Number(REQUEST_CONFIGS_KEYS.emergencyType)
			})
		)

		return () => {
			dispatch(resetRequestConfigsState())
		}
	}, [])

	return (
		<>
			<RadioInput
				name={familySituationInput.name}
				label={familySituationInput.label}
				value={familySituationInput.value}
				list={radioSelectionYesNo}
				onChange={onSelectFamilySituationInput}
			/>

			{notificationText !== '' && (
				<>{displayWarningSection(notificationText)}</>
			)}

			<Modal
				isOpen={isModalOpen}
				type={'warning'}
				openUsingSetTimeout
				cancelButtonText={pageAssets?.request_form_understood}
				onSetIsOpen={setIsModalOpen}
			>
				<div className={classes.modalContent}>
					{pageAssets?.request_form_pandemicEpidemic_modal_text}
				</div>
			</Modal>
		</>
	)
}

export default PandemicEpidemicForm
