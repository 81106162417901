import { REQUESTS_IDS, REQUEST_CONFIGS_KEYS } from '@services/constants'
import { useAppDispatch } from '@services/store'
import { populateRequestModelState } from '@services/store/request'
import {
	CheckboxList,
	RequestFormProps,
	RequestInputs,
	SelectList
} from '@utils/request'
import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import CheckboxInput from './inputs/checkbox.input'
import RadioInput from './inputs/radio.input'
import SelectInput from './inputs/select.inputs'
import requestForm from './request.form'

const SnowClearingProblemForm: FC<RequestFormProps> = ({
	inputs,
	errors,
	onInputsChange,
	onFixError
}) => {
	const {
		requests,
		pageAssets,
		radioListYesNo,
		UpdateRequestInputsArray,
		UpdateRequestInputsErrorsArray
	} = requestForm()

	enum STATEMENTS {
		first = 'nos équipements sont passés dans la rue depuis le début des précipitations',
		second = 'un ou des véhicules sont stationnés à un endroit où ils ne devraient pas (https://infostationnement.laval.ca/)',
		third = 'il est impossible de circuler dans la rue'
	}

	enum STATEMENTS_SIDEWALK {
		first = 'la chenillette est passée sur le trottoir depuis le début des précipitations',
		second = 'un ou des bacs de recyclage encombrent le trottoir',
		third = 'une ou des poubelles encombrent le trottoir',
		forth = 'un véhicule est stationné sur le trottoir',
		fifth = 'le trottoir est encombré par ... (veuillez préciser dans le champ Autres commentaires)'
	}
	const dispatch = useAppDispatch()

	const [requestType, setRequestType] = useState<string>()
	const [trueStatementList, setTrueStatementList] = useState<CheckboxList[]>()

	const [requestTypeInput, setRequestTypeInput] = useState<RequestInputs>({
		name: 'requestType',
		value: '',
		label: pageAssets?.request_form_snowClearingProblem_request_type,
		labelKey: 'request_form_snowClearingProblem_request_type',
		required: true,
		ref: useRef<HTMLSelectElement>(null)
	})

	const [snowRemovalByInput, setSnowRemovalByInput] = useState<RequestInputs>({
		name: 'snowRemovalBy',
		value: '',
		label: pageAssets?.request_form_snowClearingProblem_snow_removal_by,
		labelKey: 'request_form_snowClearingProblem_snow_removal_by',
		required: false,
		ref: useRef<HTMLSelectElement>(null)
	})

	const [injuryOccurredInput, setInjuryOccurredInput] = useState<RequestInputs>(
		{
			name: 'injuryOccurred',
			value: '',
			label: pageAssets?.request_form_snowClearingProblem_injury_occurred,
			labelKey: 'request_form_snowClearingProblem_injury_occurred',
			required: false,
			ref: useRef<HTMLInputElement>(null)
		}
	)

	const [requestConcernInput, setRequestConcernInput] = useState<RequestInputs>(
		{
			name: 'placeTypeSnowRemoval',
			value: '',
			label: pageAssets?.requestForm_snowClearingProblem_your_request_concern,
			labelKey: 'requestForm_snowClearingProblem_your_request_concern',
			required: true,
			ref: useRef<HTMLInputElement>(null)
		}
	)

	const [fireHydrantSnowRemovalInput, setFireHydrantSnowRemovalInput] =
		useState<RequestInputs>({
			name: 'fireHydrantSowRemoval',
			value: '',
			label:
				pageAssets?.request_form_snowClearingProblem_fire_hydrant_snow_removal,
			labelKey: 'request_form_snowClearingProblem_fire_hydrant_snow_removal  ',
			required: true,
			ref: useRef<HTMLInputElement>(null)
		})

	const [trueStatementInput, setTrueStatementInput] = useState<RequestInputs>({
		name: 'select_true_statement',
		value: '',
		label: pageAssets?.requestFrom_snowClearingProblem_select_true_statements,
		labelKey: 'requestFrom_snowClearingProblem_select_true_statements',
		required: false,
		ref: useRef<HTMLInputElement>(null)
	})

	const requestTypeList: SelectList[] = [
		{
			label: pageAssets?.request_form_select_yes,
			value: REQUESTS_IDS.snowDepositedOnTheRoadByCitizensOrContractors,
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets?.request_form_select_no,
			value: REQUESTS_IDS.snowClearingProblem,
			key: 'request_form_select_no'
		}
	]

	const snowRemovalByList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label:
				pageAssets?.request_form_snowClearingProblem_snow_removal_by_option_citizen,
			value: REQUEST_CONFIGS_KEYS.snowRemovalBy.citizen,
			key: 'request_form_snowClearingProblem_snow_removal_by_option_citizen'
		},
		{
			label:
				pageAssets?.request_form_snowClearingProblem_snow_removal_by_option_entrepreneur,
			value: REQUEST_CONFIGS_KEYS.snowRemovalBy.entrepreneur,
			key: 'request_form_snowClearingProblem_snow_removal_by_option_entrepreneur'
		}
	]

	const requestConcernList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label:
				pageAssets?.request_form_snowClearingProblem_your_request_concern_option_a_street,
			value: REQUEST_CONFIGS_KEYS.requestConcern.street,
			key: 'request_form_snowClearingProblem_your_request_concern_option_a_street'
		},
		{
			label:
				pageAssets?.request_form_snowClearingProblem_your_request_concern_option_a_sidewalk,
			value: REQUEST_CONFIGS_KEYS.requestConcern.sidewalk,
			key: 'request_form_snowClearingProblem_your_request_concern_option_a_sidewalk'
		},
		{
			label:
				pageAssets?.request_form_snowClearingProblem_your_request_concern_option_a_hydrant,
			value: REQUEST_CONFIGS_KEYS.requestConcern.hydrant,
			key: 'request_form_snowClearingProblem_your_request_concern_option_a_hydrant'
		}
	]

	const onRequestTypeInput = (value: string, valueKey: string) => {
		setRequestTypeInput({
			...requestTypeInput,
			value,
			valueKey
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, requestTypeInput.name))
		setRequestType(value)

		const requestSelected = requests.find(
			(request) => request.requestId === value
		)

		let title: string = ''

		if (value !== '' && requestSelected) {
			title = requestSelected.title
		}

		dispatch(
			populateRequestModelState({
				title
			})
		)

		switch (value) {
			case REQUESTS_IDS.snowDepositedOnTheRoadByCitizensOrContractors:
				setSnowRemovalByInput({
					...snowRemovalByInput,
					required: true,
					value: ''
				})
				setInjuryOccurredInput({
					...injuryOccurredInput,
					required: false,
					value: ''
				})
				break

			case REQUESTS_IDS.snowClearingProblem:
				setInjuryOccurredInput({
					...injuryOccurredInput,
					required: true,
					value: ''
				})
				setSnowRemovalByInput({
					...snowRemovalByInput,
					required: false,
					value: ''
				})
				break

			default:
				setSnowRemovalByInput({
					...snowRemovalByInput,
					required: false,
					value: ''
				})
				setInjuryOccurredInput({
					...injuryOccurredInput,
					required: false,
					value: ''
				})
				break
		}
	}

	const onSnowRemovalByInput = (value: string, valueKey: string) => {
		setSnowRemovalByInput({
			...snowRemovalByInput,
			value,
			valueKey
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, snowRemovalByInput.name))
	}

	const onRequestConcernInput = (value: string, valueKey: string) => {
		setRequestConcernInput({
			...requestConcernInput,
			value,
			valueKey
		})

		if (
			value == REQUEST_CONFIGS_KEYS.requestConcern.sidewalk ||
			value == REQUEST_CONFIGS_KEYS.requestConcern.street
		)
			onFireHydrantSnowRemovalInput(
				radioListYesNo[1]?.value,
				radioListYesNo[1]?.key
			)
		else
			onFireHydrantSnowRemovalInput(
				radioListYesNo[0]?.value,
				radioListYesNo[0]?.key
			)

		onFixError(UpdateRequestInputsErrorsArray(errors, requestConcernInput.name))
	}

	const onInjuryOccurredInput = (value: string, valueKey: string) => {
		setInjuryOccurredInput({
			...injuryOccurredInput,
			value,
			valueKey
		})

		onFixError(UpdateRequestInputsErrorsArray(errors, injuryOccurredInput.name))
	}

	const onFireHydrantSnowRemovalInput = (value: string, valueKey: string) => {
		setFireHydrantSnowRemovalInput({
			...fireHydrantSnowRemovalInput,
			value,
			valueKey
		})

		onFixError(
			UpdateRequestInputsErrorsArray(errors, fireHydrantSnowRemovalInput.name)
		)
	}

	const onTrueStatementInput = (value) => {
		const result = trueStatementList?.map((item) => {
			if (item.value == value) {
				item.checked = !item.checked
			}

			return item
		})
		setTrueStatementList(result)

		setTrueStatementInput({
			...trueStatementInput,
			value: result
				?.map((item) => item.checked && `[x] ${item.value}`)
				.filter((item) => !!item)
				.join(';\n')!
		})
	}

	useEffect(() => {
		if (
			requestConcernInput.value == REQUEST_CONFIGS_KEYS.requestConcern.street
		) {
			setTrueStatementList([
				{
					label:
						pageAssets?.requestFrom_snowClearingProblem_select_true_statements_option_first,
					key: 'requestFrom_snowClearingProblem_select_true_statements_option_first',
					value: STATEMENTS.first,
					checked: false
				},
				{
					label:
						pageAssets?.requestFrom_snowClearingProblem_select_true_statements_option_second,
					key: 'requestFrom_snowClearingProblem_select_true_statements_option_second',
					value: STATEMENTS.second,
					checked: false
				},
				{
					label:
						pageAssets?.requestFrom_snowClearingProblem_select_true_statements_option_third,
					key: 'requestFrom_snowClearingProblem_select_true_statements_option_third',
					value: STATEMENTS.third,
					checked: false
				}
			])
		} else if (
			requestConcernInput.value == REQUEST_CONFIGS_KEYS.requestConcern.sidewalk
		) {
			setTrueStatementList([
				{
					label:
						pageAssets?.requestFrom_snowClearingProblem_select_true_statements_option_sidewalk_first,
					key: 'requestFrom_snowClearingProblem_select_true_statements_option_sidewalk_first',
					value: STATEMENTS_SIDEWALK.first,
					checked: false
				},
				{
					label:
						pageAssets?.requestFrom_snowClearingProblem_select_true_statements_option_sidewalk_second,
					key: 'requestFrom_snowClearingProblem_select_true_statements_option_sidewalk_second',
					value: STATEMENTS_SIDEWALK.second,
					checked: false
				},
				{
					label:
						pageAssets?.requestFrom_snowClearingProblem_select_true_statements_option_sidewalk_third,
					key: 'requestFrom_snowClearingProblem_select_true_statements_option_sidewalk_third',
					value: STATEMENTS_SIDEWALK.third,
					checked: false
				},
				{
					label:
						pageAssets?.requestFrom_snowClearingProblem_select_true_statements_option_sidewalk_forth,
					key: 'requestFrom_snowClearingProblem_select_true_statements_option_sidewalk_forth',
					value: STATEMENTS_SIDEWALK.forth,
					checked: false
				},
				{
					label:
						pageAssets?.requestFrom_snowClearingProblem_select_true_statements_option_sidewalk_fifth,
					key: 'requestFrom_snowClearingProblem_select_true_statements_option_sidewalk_fifth',
					value: STATEMENTS_SIDEWALK.fifth,
					checked: false
				}
			])
		}
	}, [requestConcernInput])

	useEffect(() => {
		if (
			requestConcernInput.value == REQUEST_CONFIGS_KEYS.requestConcern.hydrant
		) {
			const tempRequestTypeInput = { ...requestTypeInput }
			tempRequestTypeInput.value = REQUESTS_IDS.snowClearingProblem

			dispatch(
				populateRequestModelState({
					typeId: REQUESTS_IDS.snowClearingProblem
				})
			)

			onInputsChange(UpdateRequestInputsArray(inputs, tempRequestTypeInput))
		} else {
			dispatch(
				populateRequestModelState({
					typeId: requestTypeInput.value
				})
			)

			onInputsChange(UpdateRequestInputsArray(inputs, requestTypeInput))
		}
	}, [requestTypeInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, snowRemovalByInput))
	}, [snowRemovalByInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, injuryOccurredInput))
	}, [injuryOccurredInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, requestConcernInput))

		if (
			requestConcernInput.value == REQUEST_CONFIGS_KEYS.requestConcern.hydrant
		) {
			const tempRequestTypeInput = {
				...requestTypeInput,
				value: REQUESTS_IDS.snowClearingProblem
			}

			dispatch(
				populateRequestModelState({
					typeId: REQUESTS_IDS.snowClearingProblem
				})
			)

			onInputsChange(UpdateRequestInputsArray(inputs, tempRequestTypeInput))
		}
	}, [requestConcernInput])

	useEffect(() => {
		onInputsChange(
			UpdateRequestInputsArray(inputs, fireHydrantSnowRemovalInput)
		)
	}, [fireHydrantSnowRemovalInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, trueStatementInput))
	}, [trueStatementInput])

	useEffect(() => {
		const text = document.querySelectorAll(`.checkbox-link`)
		text?.forEach((element) => {
			const link = element.querySelector('a')
			link?.setAttribute('target', '_blank')
			link?.addEventListener('click', (e) => {
				e.stopPropagation()
			})
		})
	}, [])

	return (
		<>
			<RadioInput
				name={requestTypeInput.name}
				label={requestTypeInput.label}
				value={`${requestTypeInput.value}`}
				list={requestTypeList}
				onChange={onRequestTypeInput}
				required={requestTypeInput.required}
				hasError={errors.includes(requestTypeInput.name)}
				ref={requestTypeInput.ref as RefObject<HTMLInputElement>}
			/>

			{requestType ===
				REQUESTS_IDS.snowDepositedOnTheRoadByCitizensOrContractors && (
				<SelectInput
					id={snowRemovalByInput.name}
					label={snowRemovalByInput.label}
					value={`${snowRemovalByInput.value}`}
					list={snowRemovalByList}
					onChange={onSnowRemovalByInput}
					required
					hasError={errors.includes(snowRemovalByInput.name)}
					ref={snowRemovalByInput.ref as RefObject<HTMLSelectElement>}
				/>
			)}

			{requestType === REQUESTS_IDS.snowClearingProblem && (
				<RadioInput
					name={injuryOccurredInput.name}
					label={injuryOccurredInput.label}
					value={`${injuryOccurredInput.value}`}
					list={radioListYesNo}
					onChange={onInjuryOccurredInput}
					required={injuryOccurredInput.required}
					hasError={errors.includes(injuryOccurredInput.name)}
					ref={injuryOccurredInput.ref as RefObject<HTMLInputElement>}
				/>
			)}

			<SelectInput
				id={requestConcernInput.name}
				label={requestConcernInput.label}
				value={`${requestConcernInput.value}`}
				list={requestConcernList}
				onChange={onRequestConcernInput}
				required
				hasError={errors.includes(requestConcernInput.name)}
				ref={requestConcernInput.ref as RefObject<HTMLSelectElement>}
			/>

			{(requestConcernInput.value ==
				REQUEST_CONFIGS_KEYS.requestConcern.sidewalk ||
				requestConcernInput.value ==
					REQUEST_CONFIGS_KEYS.requestConcern.street) && (
				<CheckboxInput
					name={trueStatementInput.name}
					label={trueStatementInput.label}
					value={`${trueStatementInput.value}`}
					list={trueStatementList}
					onChange={onTrueStatementInput}
					required={trueStatementInput.required}
					hasError={errors.includes(trueStatementInput.name)}
					ref={trueStatementInput.ref as RefObject<HTMLInputElement>}
					shouldNotDivide
				/>
			)}
		</>
	)
}

export default SnowClearingProblemForm
