import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import { RequestFormProps, RequestInputs, SelectList } from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import SelectInput from '@components/requestForms/inputs/select.inputs'
import TextInput from '@components/requestForms/inputs/text.inputs'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'

const BrokenBinForm: FC<RequestFormProps> = ({
	inputs,
	errors,
	onInputsChange,
	onFixError
}) => {
	const {
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputsErrorsArray,
		displayWarningSection
	} = requestForm()

	const [containerProvidedInput, setContainerProvidedInput] =
		useState<RequestInputs>({
			name: 'containerProvided',
			value: '',
			label: pageAssets?.request_form_brokenBin_containerProvided_label,
			labelKey: 'request_form_brokenBin_containerProvided_label',
			required: true,
			ref: useRef<HTMLSelectElement>(null)
		})

	const [numberOnBinInput, setNumberOnBinInput] = useState<RequestInputs>({
		name: 'numberOnBin',
		value: '',
		label: pageAssets?.request_form_brokenBin_numberOnBin_label,
		labelKey: 'request_form_brokenBin_numberOnBin_label',
		required: true,
		ref: useRef<HTMLInputElement>(null)
	})

	const binTypeList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_binType_garbage,
			value: REQUEST_CONFIGS_KEYS.binType.brokenBinGarbage,
			key: 'select_binType_garbage'
		},
		{
			label: pageAssets?.select_binType_recycling,
			value: REQUEST_CONFIGS_KEYS.binType.recycling,
			key: 'select_binType_recycling'
		},
		{
			label: pageAssets?.select_binType_compost,
			value: REQUEST_CONFIGS_KEYS.binType.compost,
			key: 'select_binType_compost'
		}
	]

	const onChangeContainerProvided = (value: string, valueKey: string) => {
		setContainerProvidedInput({ ...containerProvidedInput, value, valueKey })
		onFixError(
			UpdateRequestInputsErrorsArray(errors, containerProvidedInput.name)
		)
	}

	const onChangeNumberOnBinInput = (value: string) => {
		setNumberOnBinInput({ ...numberOnBinInput, value })
		onFixError(UpdateRequestInputsErrorsArray(errors, numberOnBinInput.name))
	}

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, containerProvidedInput))
	}, [containerProvidedInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, numberOnBinInput))
	}, [numberOnBinInput])

	return (
		<>
			<SelectInput
				id={containerProvidedInput.name}
				label={containerProvidedInput.label}
				value={containerProvidedInput.value}
				list={binTypeList}
				onChange={onChangeContainerProvided}
				hasError={errors.includes(containerProvidedInput.name)}
				required
				ref={containerProvidedInput.ref as RefObject<HTMLSelectElement>}
			/>

			<TextInput
				id={numberOnBinInput.name}
				label={numberOnBinInput.label}
				value={numberOnBinInput.value}
				onChange={onChangeNumberOnBinInput}
				hasError={errors.includes(numberOnBinInput.name)}
				required
				ref={numberOnBinInput.ref as RefObject<HTMLInputElement>}
			/>

			{displayWarningSection(
				pageAssets?.request_form_brokenBin_containerProvided_notification
			)}
		</>
	)
}

export default BrokenBinForm
