import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import SelectInput from '@components/requestForms/inputs/select.inputs'
import requestForm from '@components/requestForms/request.form'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'
import { RequestFormProps, RequestInputs, SelectList } from '@utils/request'
import TextInput from '@components/requestForms/inputs/text.inputs'

const NewBinAddOrChangeForm: FC<RequestFormProps> = ({
	inputs,
	errors,
	onInputsChange,
	onFixError
}) => {
	const {
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputsErrorsArray
	} = requestForm()

	const [newBinInput, setNewBinInput] = useState<RequestInputs>({
		name: 'newBin',
		value: '',
		label: pageAssets?.request_form_newBinAddOrChange_newBin_label,
		labelKey: 'request_form_newBinAddOrChange_newBin_label',
		required: true,
		ref: useRef<HTMLSelectElement>(null)
	})

	const [containerCollectionInput, setContainerCollectionInput] =
		useState<RequestInputs>({
			name: 'containerCollection',
			value: '',
			label:
				pageAssets?.request_form_newBinAddOrChange_containerCollection_label,
			labelKey: 'request_form_newBinAddOrChange_containerCollection_label',
			required: true,
			ref: useRef<HTMLSelectElement>(null)
		})

	const [numberOnTheBoxInput, setNumberOnTheBoxInput] = useState<RequestInputs>(
		{
			name: 'numberOnTheBox',
			value: '',
			valueKey: '',
			label: pageAssets?.request_form_newBinAddOrChange_numberOnTheBox_label,
			labelKey: 'request_form_newBinAddOrChange_numberOnTheBox_label',
			required: false
		}
	)

	const [brandOfContainerInput, setBrandOfContainerInput] =
		useState<RequestInputs>({
			name: 'brandOfContainer',
			value: '',
			valueKey: '',
			label: pageAssets?.request_form_newBinAddOrChange_brandOfContainer_label,
			labelKey: 'request_form_newBinAddOrChange_brandOfContainer_label',
			required: false
		})

	const newBinList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.request_form_select_newBin_address,
			value: REQUEST_CONFIGS_KEYS.newBin.newAddress,
			key: 'request_form_select_newBin_address'
		},
		{
			label: pageAssets?.request_form_select_newBin_additional,
			value: REQUEST_CONFIGS_KEYS.newBin.additionalBin,
			key: 'request_form_select_newBin_additional'
		},
		{
			label: pageAssets?.request_form_select_newBin_volumeChange,
			value: REQUEST_CONFIGS_KEYS.newBin.volumeChange,
			key: 'request_form_select_newBin_volumeChange'
		}
	]

	const containerCollectionList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_binType_recycling,
			value: REQUEST_CONFIGS_KEYS.binType.recycling,
			key: 'select_binType_recycling'
		},
		{
			label: pageAssets?.select_binType_compost,
			value: REQUEST_CONFIGS_KEYS.binType.compost,
			key: 'select_binType_compost'
		},
		{
			label: pageAssets?.select_binType_garbage,
			value: REQUEST_CONFIGS_KEYS.binType.garbage,
			key: 'select_binType_garbage'
		}
	]

	const onChangeNewBinInput = (value: string, valueKey: string) => {
		setNewBinInput({ ...newBinInput, value, valueKey })
		onFixError(UpdateRequestInputsErrorsArray(errors, newBinInput.name))
	}

	const onChangeContainerCollectionInput = (
		value: string,
		valueKey: string
	) => {
		setContainerCollectionInput({
			...containerCollectionInput,
			value,
			valueKey
		})
		onFixError(
			UpdateRequestInputsErrorsArray(errors, containerCollectionInput.name)
		)
	}

	const onChangeNumberOnTheBoxInput = (value: string) => {
		setNumberOnTheBoxInput({ ...numberOnTheBoxInput, value })
	}

	const onChangeBrandOfContainerInput = (value: string) => {
		setBrandOfContainerInput({ ...brandOfContainerInput, value })
	}

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, newBinInput))
	}, [newBinInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, containerCollectionInput))
	}, [containerCollectionInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, numberOnTheBoxInput))
	}, [numberOnTheBoxInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, brandOfContainerInput))
	}, [brandOfContainerInput])

	return (
		<>
			<SelectInput
				id={newBinInput.name}
				label={newBinInput.label}
				value={newBinInput.value}
				list={newBinList}
				onChange={onChangeNewBinInput}
				hasError={errors.includes(newBinInput.name)}
				required
				ref={newBinInput.ref as RefObject<HTMLSelectElement>}
			/>

			<SelectInput
				id={containerCollectionInput.name}
				label={containerCollectionInput.label}
				value={containerCollectionInput.value}
				list={containerCollectionList}
				onChange={onChangeContainerCollectionInput}
				hasError={errors.includes(containerCollectionInput.name)}
				required
				ref={containerCollectionInput.ref as RefObject<HTMLSelectElement>}
			/>

			{newBinInput.value === REQUEST_CONFIGS_KEYS.newBin.volumeChange && (
				<>
					<TextInput
						id={numberOnTheBoxInput.name}
						label={numberOnTheBoxInput.label}
						value={numberOnTheBoxInput.value}
						onChange={onChangeNumberOnTheBoxInput}
					/>

					<TextInput
						id={brandOfContainerInput.name}
						label={brandOfContainerInput.label}
						value={brandOfContainerInput.value}
						onChange={onChangeBrandOfContainerInput}
					/>
				</>
			)}
		</>
	)
}

export default NewBinAddOrChangeForm
