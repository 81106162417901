import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import { RequestFormProps, RequestInputs } from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import TextInput from '@components/requestForms/inputs/text.inputs'
import { useAppDispatch } from '@services/store'
import { AppDispatch } from '@services/store/type'
import { populateRequestModelState } from '@services/store/request'

const OthersRequestsForm: FC<RequestFormProps> = ({
	inputs,
	errors,
	onInputsChange,
	onFixError
}) => {
	const dispatch: AppDispatch = useAppDispatch()
	const {
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputsErrorsArray
	} = requestForm()

	const [subject, setSubject] = useState<RequestInputs>({
		name: 'subject',
		value: '',
		label: pageAssets?.request_form_othersRequests_subject,
		labelKey: 'request_form_othersRequests_subject',
		required: true,
		ref: useRef<HTMLInputElement>(null)
	})

	const onChangeSubject = (value: string) => {
		setSubject({ ...subject, value })
		onFixError(UpdateRequestInputsErrorsArray(errors, subject.name))

		dispatch(
			populateRequestModelState({
				title: value
			})
		)
	}

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, subject))
	}, [subject])

	return (
		<>
			<TextInput
				id={subject.name}
				label={subject.label}
				value={subject.value}
				required
				disabled={subject.disabled}
				hasError={errors.includes(subject.name)}
				onChange={onChangeSubject}
				maxCharacters={200}
				ref={subject.ref as RefObject<HTMLInputElement>}
			/>
		</>
	)
}

export default OthersRequestsForm
