import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
	RadioList,
	RequestFormProps,
	RequestInputs,
	SelectList
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import SelectInput from '@components/requestForms/inputs/select.inputs'
import { Request } from 'typings/shared'
import { REQUESTS_IDS, REQUEST_CONFIGS_KEYS } from '@services/constants'
import { useAppDispatch } from '@services/store'
import { populateRequestModelState } from '@services/store/request'
import config from '@utils/config'
import RadioInput from '@components/requestForms/inputs/radio.input'

const FloorLampAndLighting: FC<RequestFormProps> = ({
	inputs,
	errors,
	onInputsChange,
	onFixError
}) => {
	const dispatch = useAppDispatch()

	const {
		pageAssets,
		requests,
		UpdateRequestInputsArray,
		displayWarningSection,
		UpdateRequestInputsErrorsArray,
		filterMultipleRequests
	} = requestForm()

	const [requestId, setRequestId] = useState<string>('')
	const [showRepairAllQuestion, setShowRepairAllQuestion] =
		useState<boolean>(false)
	const [showReasonQuestion, setShowReasonQuestion] = useState<boolean>(false)
	const [showNatureBreakageQuestion, setShowNatureBreakageQuestion] =
		useState<boolean>(false)
	const [infoTextLabelAlert, setInfoTextLabelAlert] = useState<string>('')
	const [wishInput, setWishInput] = useState<RequestInputs>({
		name: 'wish',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_floorLampAndLighting_wish_label,
		labelKey: 'request_form_floorLampAndLighting_wish_label',
		required: true,
		ref: useRef<HTMLSelectElement>(null)
	})
	const [lightingInterventionInput, setLightingInterventionInput] =
		useState<RequestInputs>({
			name: 'lightingIntervention',
			value: '',
			valueKey: '',
			label: '',
			labelKey: '',
			required: false
		})
	const [sectorOffInput, setSectorOffInput] = useState<RequestInputs>({
		name: 'sectorOff',
		value: '',
		valueKey: '',
		label: '',
		labelKey: '',
		required: false
	})
	const [locationLightingInput, setLocationLightingInput] =
		useState<RequestInputs>({
			name: 'locationLighting',
			value: '',
			valueKey: '',
			label: '',
			labelKey: '',
			required: false
		})
	const [propertyTypeInput, setPropertyTypeInput] = useState<RequestInputs>({
		name: 'propertyType',
		value: '',
		valueKey: '',
		label: '',
		labelKey: '',
		required: false
	})
	const [lightingLocatedInput, setLightingLocatedInput] =
		useState<RequestInputs>({
			name: 'lightingLocated',
			value: '',
			valueKey: '',
			label:
				pageAssets?.request_form_floorLampAndLighting_lightingLocated_label,
			labelKey: 'request_form_floorLampAndLighting_lightingLocated_label',
			required: true,
			ref: useRef<HTMLSelectElement>(null)
		})
	const [repairAllInput, setRepairAllInput] = useState<RequestInputs>({
		name: 'repairAll',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_floorLampAndLighting_repairAll_label,
		labelKey: 'request_form_floorLampAndLighting_repairAll_label',
		required: false,
		ref: useRef<HTMLInputElement>(null)
	})
	const [situationRecurrentInput, setSituationRecurrentInput] =
		useState<RequestInputs>({
			name: 'situationRecurrent',
			value: '',
			valueKey: '',
			label:
				pageAssets?.request_form_floorLampAndLighting_situationRecurrent_label,
			labelKey: 'request_form_floorLampAndLighting_situationRecurrent_label',
			required: false
		})
	const [reasonInput, setReasonInput] = useState<RequestInputs>({
		name: 'reason',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_floorLampAndLighting_reason_label,
		labelKey: 'request_form_floorLampAndLighting_reason_label',
		required: false
	})
	const [natureBreakageInput, setNatureBreakageInput] = useState<RequestInputs>(
		{
			name: 'natureBreakage',
			value: '',
			valueKey: '',
			label: pageAssets?.request_form_floorLampAndLighting_natureBreakage_label,
			labelKey: 'request_form_floorLampAndLighting_natureBreakage_label',
			required: false
		}
	)
	const [lightingTypeInput, setLightingTypeInput] = useState<RequestInputs>({
		name: 'lightingType',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_floorLampAndLighting_lightingType_label,
		labelKey: 'request_form_floorLampAndLighting_lightingType_label',
		required: false
	})

	const wishList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.request_form_select_floorLampLighting_addition,
			value: pageAssets?.request_form_select_floorLampLighting_addition,
			key: 'request_form_select_floorLampLighting_addition'
		},
		{
			label: pageAssets?.request_form_select_floorLampLighting_repair,
			value: pageAssets?.request_form_select_floorLampLighting_repair,
			key: 'request_form_select_floorLampLighting_repair'
		},
		{
			label: pageAssets?.request_form_select_floorLampLighting_report,
			value: pageAssets?.request_form_select_floorLampLighting_report,
			key: 'request_form_select_floorLampLighting_report'
		}
	]

	const lightingLocatedList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.request_form_select_floorLampLighting_street,
			value: pageAssets?.request_form_select_floorLampLighting_street,
			key: 'request_form_select_floorLampLighting_street'
		},
		{
			label: pageAssets?.request_form_select_floorLampLighting_landOrBuilding,
			value: pageAssets?.request_form_select_floorLampLighting_landOrBuilding,
			key: 'request_form_select_floorLampLighting_landOrBuilding'
		},
		{
			label: pageAssets?.request_form_select_floorLampLighting_park,
			value: pageAssets?.request_form_select_floorLampLighting_park,
			key: 'request_form_select_floorLampLighting_park'
		},
		{
			label: pageAssets?.request_form_select_floorLampLighting_privateLand,
			value: pageAssets?.request_form_select_floorLampLighting_privateLand,
			key: 'request_form_select_floorLampLighting_privateLand'
		}
	]

	const repairAllList: RadioList[] = [
		{
			label: pageAssets?.request_form_select_floorLampLighting_one,
			value: pageAssets?.request_form_select_floorLampLighting_one,
			key: 'request_form_select_floorLampLighting_one'
		},
		{
			label: pageAssets?.request_form_select_floorLampLighting_all,
			value: pageAssets?.request_form_select_floorLampLighting_all,
			key: 'request_form_select_floorLampLighting_all'
		}
	]

	const situationRecurrentList: RadioList[] = [
		{
			label: pageAssets?.request_form_select_yes,
			value: pageAssets?.request_form_select_yes,
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets?.request_form_select_no_fistTime,
			value: pageAssets?.request_form_select_no_fistTime,
			key: 'request_form_select_no_fistTime'
		}
	]

	const reasonList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.request_form_select_floorLampLighting_noLamp,
			value: pageAssets?.request_form_select_floorLampLighting_noLamp,
			key: 'request_form_select_floorLampLighting_noLamp'
		},
		{
			label: pageAssets?.request_form_select_floorLampLighting_lackIntensity,
			value: pageAssets?.request_form_select_floorLampLighting_lackIntensity,
			key: 'request_form_select_floorLampLighting_lackIntensity'
		},
		{
			label: pageAssets?.request_form_select_floorLampLighting_otherLampPost,
			value: pageAssets?.request_form_select_floorLampLighting_otherLampPost,
			key: 'request_form_select_floorLampLighting_otherLampPost'
		},
		{
			label: pageAssets?.request_form_select_floorLampLighting_other,
			value: pageAssets?.request_form_select_floorLampLighting_other,
			key: 'request_form_select_floorLampLighting_other'
		}
	]

	const natureBreakageList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.request_form_select_floorLampLighting_day,
			value: pageAssets?.request_form_select_floorLampLighting_day,
			key: 'request_form_select_floorLampLighting_day'
		},
		{
			label: pageAssets?.request_form_select_floorLampLighting_night,
			value: pageAssets?.request_form_select_floorLampLighting_night,
			key: 'request_form_select_floorLampLighting_night'
		},
		{
			label: pageAssets?.request_form_select_floorLampLighting_flashing,
			value: pageAssets?.request_form_select_floorLampLighting_flashing,
			key: 'request_form_select_floorLampLighting_flashing'
		},
		{
			label: pageAssets?.request_form_select_floorLampLighting_exposedWires,
			value: pageAssets?.request_form_select_floorLampLighting_exposedWires,
			key: 'request_form_select_floorLampLighting_exposedWires'
		},
		{
			label: pageAssets?.request_form_select_floorLampLighting_damaged,
			value: pageAssets?.request_form_select_floorLampLighting_damaged,
			key: 'request_form_select_floorLampLighting_damaged'
		},
		{
			label: pageAssets?.request_form_select_floorLampLighting_other,
			value: pageAssets?.request_form_select_floorLampLighting_other,
			key: 'request_form_select_floorLampLighting_other'
		}
	]

	const lightingTypeList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.request_form_select_floorLampLighting_continuously,
			value: pageAssets?.request_form_select_floorLampLighting_continuously,
			key: 'request_form_select_floorLampLighting_continuously'
		},
		{
			label: pageAssets?.request_form_select_floorLampLighting_motionSensor,
			value: pageAssets?.request_form_select_floorLampLighting_motionSensor,
			key: 'request_form_select_floorLampLighting_motionSensor'
		}
	]

	const onSelectWishInput = (value: string, valueKey: string) => {
		let infoTextAlertTemp: string = ''

		clearConfigsInputs()
		onFixError(UpdateRequestInputsErrorsArray(errors, wishInput.name))
		setShowReasonQuestion(false)
		setShowRepairAllQuestion(false)
		setShowNatureBreakageQuestion(false)
		setLightingLocatedInput({
			...lightingLocatedInput,
			value: '',
			valueKey: ''
		})
		setSituationRecurrentInput({
			...situationRecurrentInput,
			value: '',
			valueKey: ''
		})
		setRepairAllInput({
			...repairAllInput,
			value: '',
			valueKey: '',
			required: false
		})
		setReasonInput({ ...reasonInput, value: '', valueKey: '' })
		setNatureBreakageInput({ ...natureBreakageInput, value: '', valueKey: '' })
		setLightingTypeInput({ ...lightingTypeInput, value: '', valueKey: '' })
		changeRequestTypeForm('')

		switch (value) {
			case pageAssets?.request_form_select_floorLampLighting_addition:
				setLightingInterventionInput({
					...lightingInterventionInput,
					value: REQUEST_CONFIGS_KEYS.lightingIntervention.addition,
					required: true
				})
				setSectorOffInput({
					...sectorOffInput,
					value: REQUEST_CONFIGS_KEYS.no,
					required: true
				})
				break
			case pageAssets?.request_form_select_floorLampLighting_repair:
				setLightingInterventionInput({
					...lightingInterventionInput,
					value: REQUEST_CONFIGS_KEYS.lightingIntervention.repair,
					required: true
				})
				infoTextAlertTemp =
					pageAssets?.request_form_floorLampLighting_info_repair_label
				break
			case pageAssets?.request_form_select_floorLampLighting_report:
				changeRequestTypeForm(REQUESTS_IDS.inconvenientLighting)
				infoTextAlertTemp =
					pageAssets?.request_form_floorLampLighting_info_report_label
				break
			default:
				break
		}

		setInfoTextLabelAlert(infoTextAlertTemp)
		setWishInput({ ...wishInput, value, valueKey })
	}

	const onSelectLightingLocatedInput = (value: string, valueKey: string) => {
		const isFirstQuestionAnswerAddition: boolean =
			wishInput.value ===
			pageAssets?.request_form_select_floorLampLighting_addition
		const isFirstQuestionAnswerRepair: boolean =
			wishInput.value ===
			pageAssets?.request_form_select_floorLampLighting_repair

		let required: boolean = true
		let resetRequestType: boolean = true
		let doShowRepairAllQuestion: boolean = false
		let doShowReasonQuestion: boolean = false
		let doShowNatureBreakageQuestion: boolean = false

		onFixError(
			UpdateRequestInputsErrorsArray(errors, lightingLocatedInput.name)
		)

		if (wishInput.value === REQUESTS_IDS.inconvenientLighting) {
			clearConfigsInputs()
			resetRequestType = false
		}

		if (isFirstQuestionAnswerAddition) {
			setSectorOffInput({
				...sectorOffInput,
				value: REQUEST_CONFIGS_KEYS.no,
				required: true
			})
		}

		switch (value) {
			case pageAssets?.request_form_select_floorLampLighting_street:
				if (isFirstQuestionAnswerRepair) {
					doShowNatureBreakageQuestion = true
					doShowRepairAllQuestion = true
					setRepairAllInput({
						...repairAllInput,
						value: '',
						valueKey: '',
						required: true
					})

					setSectorOffInput({ ...sectorOffInput, value: '', required: true })
				}

				if (isFirstQuestionAnswerAddition) {
					doShowReasonQuestion = true
				}

				if (requestId === '') {
					changeRequestTypeForm(REQUESTS_IDS.streetLighting)
					break
				}

				setLocationLightingInput({
					...locationLightingInput,
					value: REQUEST_CONFIGS_KEYS.locationLighting.street,
					required
				})
				setPropertyTypeInput({
					...propertyTypeInput,
					value: REQUEST_CONFIGS_KEYS.property.city,
					required
				})
				break
			case pageAssets?.request_form_select_floorLampLighting_landOrBuilding:
				if (isFirstQuestionAnswerRepair) {
					doShowNatureBreakageQuestion = true
				}

				if (isFirstQuestionAnswerAddition) {
					doShowReasonQuestion = true
				}

				if (requestId === '') {
					changeRequestTypeForm(REQUESTS_IDS.buildingLighting)
					break
				}

				setLocationLightingInput({
					...locationLightingInput,
					value: REQUEST_CONFIGS_KEYS.locationLighting.building,
					required
				})
				setPropertyTypeInput({
					...propertyTypeInput,
					value: REQUEST_CONFIGS_KEYS.property.city,
					required
				})
				break
			case pageAssets?.request_form_select_floorLampLighting_park:
				if (isFirstQuestionAnswerRepair) {
					doShowNatureBreakageQuestion = true
				}

				if (isFirstQuestionAnswerAddition) {
					doShowReasonQuestion = true
				}

				if (requestId === '') {
					changeRequestTypeForm(REQUESTS_IDS.parkLighting)
					break
				}

				setLocationLightingInput({
					...locationLightingInput,
					value: REQUEST_CONFIGS_KEYS.locationLighting.park,
					required
				})
				setPropertyTypeInput({
					...propertyTypeInput,
					value: REQUEST_CONFIGS_KEYS.property.city,
					required
				})
				break
			case pageAssets?.request_form_select_floorLampLighting_privateLand:
				if (requestId === '') {
					changeRequestTypeForm(REQUESTS_IDS.inconvenientLighting)
					break
				}

				setLocationLightingInput({
					...locationLightingInput,
					value: REQUEST_CONFIGS_KEYS.locationLighting.building,
					required
				})
				setPropertyTypeInput({
					...propertyTypeInput,
					value: REQUEST_CONFIGS_KEYS.property.private,
					required
				})
				break
			default:
				if (resetRequestType) {
					changeRequestTypeForm('')
				}
				break
		}

		if (!doShowNatureBreakageQuestion) {
			setNatureBreakageInput({
				...natureBreakageInput,
				value: '',
				valueKey: ''
			})
		}

		if (!doShowRepairAllQuestion) {
			setRepairAllInput({
				...repairAllInput,
				value: '',
				valueKey: '',
				required: false
			})
		}

		if (!doShowReasonQuestion) {
			setReasonInput({ ...reasonInput, value: '', valueKey: '' })
		}

		setShowNatureBreakageQuestion(doShowNatureBreakageQuestion)
		setShowReasonQuestion(doShowReasonQuestion)
		setShowRepairAllQuestion(doShowRepairAllQuestion)
		setLightingLocatedInput({ ...lightingLocatedInput, value, valueKey })
	}

	const onSelectRepairAllInput = (value: string, valueKey: string) => {
		setRepairAllInput({ ...repairAllInput, value, valueKey })
		onFixError(UpdateRequestInputsErrorsArray(errors, repairAllInput.name))

		if (value === pageAssets?.request_form_select_floorLampLighting_one) {
			setSectorOffInput({
				...sectorOffInput,
				value: REQUEST_CONFIGS_KEYS.no,
				required: true
			})

			return
		}

		setSectorOffInput({
			...sectorOffInput,
			value: REQUEST_CONFIGS_KEYS.yes,
			required: true
		})
	}

	const onSelectSituationRecurrentInput = (value: string, valueKey: string) => {
		setSituationRecurrentInput({ ...situationRecurrentInput, value, valueKey })
	}

	const onSelectReasonInput = (value: string, valueKey: string) => {
		setReasonInput({ ...reasonInput, value, valueKey })
	}

	const onSelectNatureBreakageInput = (value: string, valueKey: string) => {
		setNatureBreakageInput({ ...natureBreakageInput, value, valueKey })
	}

	const onSelectLightingTypeInput = (value: string, valueKey: string) => {
		setLightingTypeInput({ ...lightingTypeInput, value, valueKey })
	}

	const clearConfigsInputs = () => {
		setLightingInterventionInput({
			...lightingInterventionInput,
			value: '',
			required: false
		})
		setSectorOffInput({ ...sectorOffInput, value: '', required: false })
		setLocationLightingInput({
			...locationLightingInput,
			value: '',
			required: false
		})
		setPropertyTypeInput({
			...propertyTypeInput,
			value: '',
			required: false
		})
	}

	const changeRequestTypeForm = (requestId: string) => {
		const requestSelected: Request | undefined = requests.find(
			(request) => request.requestId === requestId
		)

		if (requestId !== '' && requestSelected) {
			setRequestId(requestId)

			dispatch(
				populateRequestModelState({
					title: requestSelected.title,
					typeId: requestId
				})
			)
			return
		}

		setRequestId(requestId)

		dispatch(
			populateRequestModelState({
				title: '',
				typeId: requestId
			})
		)
	}

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, wishInput))
	}, [wishInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, lightingInterventionInput))
	}, [lightingInterventionInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, sectorOffInput))
	}, [sectorOffInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, locationLightingInput))
	}, [locationLightingInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, lightingLocatedInput))
	}, [lightingLocatedInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, propertyTypeInput))
	}, [propertyTypeInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, repairAllInput))
	}, [repairAllInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, situationRecurrentInput))
	}, [situationRecurrentInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, reasonInput))
	}, [reasonInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, natureBreakageInput))
	}, [natureBreakageInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, lightingTypeInput))
	}, [lightingTypeInput])

	useEffect(() => {
		filterMultipleRequests(
			config.request.create.floorLampAndLighting.requestTypeListIncludes
		)
	}, [])

	return (
		<>
			<SelectInput
				id={wishInput.name}
				label={wishInput.label}
				value={wishInput.value}
				list={wishList}
				required
				onChange={onSelectWishInput}
				hasError={errors.includes(wishInput.name)}
				ref={wishInput.ref as RefObject<HTMLSelectElement>}
			/>

			<SelectInput
				id={lightingLocatedInput.name}
				label={lightingLocatedInput.label}
				value={lightingLocatedInput.value}
				list={lightingLocatedList}
				required
				onChange={onSelectLightingLocatedInput}
				hasError={errors.includes(lightingLocatedInput.name)}
				ref={lightingLocatedInput.ref as RefObject<HTMLSelectElement>}
			/>

			{showRepairAllQuestion && (
				<RadioInput
					name={repairAllInput.name}
					label={repairAllInput.label}
					value={repairAllInput.value}
					list={repairAllList}
					onChange={onSelectRepairAllInput}
					required={repairAllInput.required}
					hasError={errors.includes(repairAllInput.name)}
					ref={repairAllInput.ref as RefObject<HTMLInputElement>}
				/>
			)}

			{wishInput.value ===
				pageAssets?.request_form_select_floorLampLighting_report && (
				<>
					<RadioInput
						name={situationRecurrentInput.name}
						label={situationRecurrentInput.label}
						value={situationRecurrentInput.value}
						list={situationRecurrentList}
						onChange={onSelectSituationRecurrentInput}
					/>
					<SelectInput
						id={lightingTypeInput.name}
						label={lightingTypeInput.label}
						value={lightingTypeInput.value}
						list={lightingTypeList}
						onChange={onSelectLightingTypeInput}
					/>
				</>
			)}

			{showReasonQuestion && (
				<SelectInput
					id={reasonInput.name}
					label={reasonInput.label}
					value={reasonInput.value}
					list={reasonList}
					onChange={onSelectReasonInput}
				/>
			)}

			{showNatureBreakageQuestion && (
				<SelectInput
					id={natureBreakageInput.name}
					label={natureBreakageInput.label}
					value={natureBreakageInput.value}
					list={natureBreakageList}
					onChange={onSelectNatureBreakageInput}
				/>
			)}

			{infoTextLabelAlert !== '' && displayWarningSection(infoTextLabelAlert)}
		</>
	)
}

export default FloorLampAndLighting
