import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
	RequestFormProps,
	RequestInputs,
	SelectImageList
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import { curbImage, sidewalkImage } from '@images/icons'
import SelectImageInput from '@components/requestForms/inputs/image.input'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'
import RadioInput from '@components/requestForms/inputs/radio.input'

const CurbAndSidewalkForm: FC<RequestFormProps> = ({
	inputs,
	errors,
	onInputsChange,
	onFixError
}) => {
	const {
		pageAssets,
		radioSelectionYesNo,
		UpdateRequestInputsArray,
		UpdateRequestInputsErrorsArray,
		displayWarningSection
	} = requestForm()

	const [itemToRepairInput, setItemToRepairInput] = useState<RequestInputs>({
		name: 'itemToRepair',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_curbAndSidewalk_itemToRepair_label,
		labelKey: 'request_form_curbAndSidewalk_itemToRepair_label',
		required: true,
		ref: useRef<HTMLImageElement>(null)
	})
	const [immediateAttentionInput, setImmediateAttentionInput] =
		useState<RequestInputs>({
			name: 'immediateAttentionInput',
			value: '',
			valueKey: '',
			label: pageAssets?.request_form_curbAndSidewalk_immediateAttention_label,
			labelKey: 'request_form_curbAndSidewalk_immediateAttention_label',
			required: false
		})

	const [immediateAttention, setImmediateAttention] = useState<RequestInputs>({
		name: 'immediateAttention',
		value: '',
		valueKey: '',
		label: '',
		labelKey: '',
		required: false
	})

	const itemToRepairList: SelectImageList[] = [
		{
			label: pageAssets?.request_form_select_curbAndSidewalk_sidewalk,
			value: pageAssets?.request_form_select_curbAndSidewalk_sidewalk,
			key: 'request_form_select_curbAndSidewalk_sidewalk',
			img: sidewalkImage
		},
		{
			label: pageAssets?.request_form_select_curbAndSidewalk_curb,
			value: pageAssets?.request_form_select_curbAndSidewalk_curb,
			key: 'request_form_select_curbAndSidewalk_curb',
			img: curbImage
		},
		{
			label: '',
			value: '',
			key: '',
			img: ''
		}
	]

	const onSelectItemToRepairInput = (value: string, valueKey: string) => {
		setItemToRepairInput({ ...itemToRepairInput, value, valueKey })
		onFixError(UpdateRequestInputsErrorsArray(errors, itemToRepairInput.name))
	}

	const onSelectImmediateAttentionInput = (value: string, valueKey: string) => {
		setImmediateAttentionInput({ ...immediateAttentionInput, value, valueKey })

		if (value === REQUEST_CONFIGS_KEYS.yes) {
			setImmediateAttention({
				...immediateAttention,
				value: 'true',
				required: true
			})
			return
		}

		setImmediateAttention({ ...immediateAttention, value: '', required: false })
	}

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, itemToRepairInput))
	}, [itemToRepairInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, immediateAttentionInput))
	}, [immediateAttentionInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, immediateAttention))
	}, [immediateAttention])

	return (
		<>
			<SelectImageInput
				id={itemToRepairInput.name}
				label={itemToRepairInput.label}
				value={itemToRepairInput.value}
				list={itemToRepairList}
				required
				ref={itemToRepairInput.ref as RefObject<HTMLImageElement>}
				onChange={onSelectItemToRepairInput}
			/>

			<RadioInput
				name={immediateAttentionInput.name}
				label={immediateAttentionInput.label}
				value={immediateAttentionInput.value}
				list={radioSelectionYesNo}
				onChange={onSelectImmediateAttentionInput}
			/>

			{displayWarningSection(
				pageAssets?.request_form_curbAndSidewalk_notification
			)}
		</>
	)
}

export default CurbAndSidewalkForm
