import React, { FC, useEffect, useState } from 'react'
import { RequestFormProps, RequestInputs, SelectList } from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import SelectInput from '@components/requestForms/inputs/select.inputs'
import RadioInput from '@components/requestForms/inputs/radio.input'

const SwimmingPoolSpaForm: FC<RequestFormProps> = ({
	inputs,
	onInputsChange
}) => {
	const {
		pageAssets,
		radioSelectionYesNo,
		radioSelectionYesNoDoNotKnow,
		UpdateRequestInputsArray
	} = requestForm()

	const [workTypeInput, setWorkTypeInput] = useState<RequestInputs>({
		name: 'workType',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_swimmingPoolSpa_workType_label,
		labelKey: 'request_form_swimmingPoolSpa_workType_label',
		required: false
	})

	const [installationInput, setInstallationInput] = useState<RequestInputs>({
		name: 'installation',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_swimmingPoolSpa_installation_label,
		labelKey: 'request_form_swimmingPoolSpa_installation_label',
		required: false
	})

	const [locationInput, setLocationInput] = useState<RequestInputs>({
		name: 'location',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_swimmingPoolSpa_location_label,
		labelKey: 'request_form_swimmingPoolSpa_location_label',
		required: false
	})

	const [removalInput, setRemovalInput] = useState<RequestInputs>({
		name: 'removal',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_swimmingPoolSpa_removal_label,
		labelKey: 'request_form_swimmingPoolSpa_removal_label',
		required: false
	})

	const [poolTaxedInput, setPoolTaxedInput] = useState<RequestInputs>({
		name: 'poolTaxed',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_swimmingPoolSpa_poolTaxed_label,
		labelKey: 'request_form_swimmingPoolSpa_poolTaxed_label',
		required: false
	})

	const [poolDismantledInput, setPoolDismantledInput] = useState<RequestInputs>(
		{
			name: 'poolDismantled',
			value: '',
			valueKey: '',
			label: pageAssets?.request_form_swimmingPoolSpa_poolDismantled_label,
			labelKey: 'request_form_swimmingPoolSpa_poolDismantled_label',
			required: false
		}
	)

	const [poolReplacedInput, setPoolReplacedInput] = useState<RequestInputs>({
		name: 'poolReplaced',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_swimmingPoolSpa_poolReplaced_label,
		labelKey: 'request_form_swimmingPoolSpa_poolReplaced_label',
		required: false
	})

	const workTypeSelection: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.request_form_select_installation,
			value: pageAssets?.request_form_select_installation,
			key: 'request_form_select_installation'
		},
		{
			label: pageAssets?.request_form_select_removal,
			value: pageAssets?.request_form_select_removal,
			key: 'request_form_select_removal'
		}
	]

	const poolSpaSelection: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.request_form_select_inground_pool,
			value: pageAssets?.request_form_select_inground_pool,
			key: 'request_form_select_inground_pool'
		},
		{
			label: pageAssets?.request_form_select_above_ground_pool,
			value: pageAssets?.request_form_select_above_ground_pool,
			key: 'request_form_select_above_ground_pool'
		},
		{
			label: pageAssets?.request_form_select_spa,
			value: pageAssets?.request_form_select_spa,
			key: 'request_form_select_spa'
		}
	]

	const clearValuesForRemovalSelected = (value: string) => {
		if (value === '' || value === pageAssets?.request_form_select_spa) {
			setPoolTaxedInput({ ...poolTaxedInput, value: '', valueKey: '' })
			setPoolDismantledInput({
				...poolDismantledInput,
				value: '',
				valueKey: ''
			})
			setPoolReplacedInput({ ...poolReplacedInput, value: '', valueKey: '' })
		}
	}

	const onChangeWorkTypeInput = (value: string, valueKey: string) => {
		setWorkTypeInput({ ...workTypeInput, value, valueKey })
		setInstallationInput({ ...installationInput, value: '', valueKey: '' })
		setLocationInput({ ...locationInput, value: '', valueKey: '' })
		setRemovalInput({ ...removalInput, value: '', valueKey: '' })
		setPoolTaxedInput({ ...poolTaxedInput, value: '', valueKey: '' })
		setPoolDismantledInput({ ...poolDismantledInput, value: '', valueKey: '' })
		setPoolReplacedInput({ ...poolReplacedInput, value: '', valueKey: '' })
	}

	const onChangeInstallationInput = (value: string, valueKey: string) =>
		setInstallationInput({ ...installationInput, value, valueKey })

	const onChangeLocationInput = (value: string, valueKey: string) =>
		setLocationInput({ ...locationInput, value, valueKey })

	const onChangeRemovalInput = (value: string, valueKey: string) => {
		setRemovalInput({ ...removalInput, value, valueKey })
		clearValuesForRemovalSelected(value)
	}

	const onChangePoolTaxedInput = (value: string, valueKey: string) =>
		setPoolTaxedInput({ ...poolTaxedInput, value, valueKey })

	const onChangePoolDismantledInput = (value: string, valueKey: string) =>
		setPoolDismantledInput({ ...poolDismantledInput, value, valueKey })

	const onChangePoolReplacedInput = (value: string, valueKey: string) =>
		setPoolReplacedInput({ ...poolReplacedInput, value, valueKey })

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, workTypeInput))
	}, [workTypeInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, installationInput))
	}, [installationInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, locationInput))
	}, [locationInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, removalInput))
	}, [removalInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, poolTaxedInput))
	}, [poolTaxedInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, poolDismantledInput))
	}, [poolDismantledInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, poolReplacedInput))
	}, [poolReplacedInput])

	return (
		<>
			<SelectInput
				id={workTypeInput.name}
				value={workTypeInput.value}
				label={workTypeInput.label}
				list={workTypeSelection}
				onChange={onChangeWorkTypeInput}
			/>
			{workTypeInput.value === pageAssets?.request_form_select_installation && (
				<>
					<SelectInput
						id={installationInput.name}
						value={installationInput.value}
						label={installationInput.label}
						list={poolSpaSelection}
						onChange={onChangeInstallationInput}
					/>

					<RadioInput
						name={locationInput.name}
						value={locationInput.value}
						label={locationInput.label}
						list={radioSelectionYesNo}
						onChange={onChangeLocationInput}
					/>
				</>
			)}

			{workTypeInput.value === pageAssets?.request_form_select_removal && (
				<SelectInput
					id={removalInput.name}
					value={removalInput.value}
					label={removalInput.label}
					list={poolSpaSelection}
					onChange={onChangeRemovalInput}
				/>
			)}

			{(removalInput.value === pageAssets?.request_form_select_inground_pool ||
				removalInput.value ===
					pageAssets?.request_form_select_above_ground_pool) && (
				<>
					<RadioInput
						name={poolTaxedInput.name}
						value={poolTaxedInput.value}
						label={poolTaxedInput.label}
						list={radioSelectionYesNoDoNotKnow}
						onChange={onChangePoolTaxedInput}
					/>

					<RadioInput
						name={poolDismantledInput.name}
						value={poolDismantledInput.value}
						label={poolDismantledInput.label}
						list={radioSelectionYesNo}
						onChange={onChangePoolDismantledInput}
					/>

					<RadioInput
						name={poolReplacedInput.name}
						value={poolReplacedInput.value}
						label={poolReplacedInput.label}
						list={radioSelectionYesNo}
						onChange={onChangePoolReplacedInput}
					/>
				</>
			)}
		</>
	)
}

export default SwimmingPoolSpaForm
