import React, { FC, useEffect, useState } from 'react'
import { RequestFormProps, RequestInputs } from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import RadioInput from '@components/requestForms/inputs/radio.input'
import { makeClasses } from '@utils/styles'
import Modal from '@components/ui/modal'
import { Colors } from '@utils/css-variables'

const classes: { modalContent: string } = makeClasses({
	modalContent: {
		color: Colors.black2,
		fontSize: '16px',
		lineHeight: '26px',
		textAlign: 'center'
	}
})

const FloodingForm: FC<RequestFormProps> = ({ inputs, onInputsChange }) => {
	const {
		pageAssets,
		radioSelectionYesNo,
		radioSelectionYesNoNotApplicable,
		UpdateRequestInputsArray,
		displayWarningSection
	} = requestForm()

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

	const [floodingSituation, setFloodingSituation] = useState<RequestInputs>({
		name: 'floodingSituation',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_flooding_floodingSituation_label,
		labelKey: 'request_form_flooding_floodingSituation_label',
		required: false
	})

	const [workFollowingFlooding, setWorkFollowingFlooding] =
		useState<RequestInputs>({
			name: 'workFollowingFlooding',
			value: '',
			valueKey: '',
			label: pageAssets?.request_form_flooding_workFollowingFlooding_label,
			labelKey: 'request_form_flooding_workFollowingFlooding_label',
			required: false
		})

	const onSelectFloodingSituation = (value: string, valueKey: string) => {
		setFloodingSituation({ ...floodingSituation, value, valueKey })
		if (floodingSituation.value === pageAssets?.request_form_select_no) {
			setWorkFollowingFlooding({
				...workFollowingFlooding,
				value: '',
				valueKey: ''
			})
		}
	}

	const onSelectWorkFollowingFlooding = (value: string, valueKey: string) =>
		setWorkFollowingFlooding({ ...workFollowingFlooding, value, valueKey })

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, floodingSituation))
	}, [floodingSituation])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, workFollowingFlooding))
	}, [workFollowingFlooding])

	return (
		<>
			<RadioInput
				name={floodingSituation.name}
				label={floodingSituation.label}
				value={floodingSituation.value}
				list={radioSelectionYesNo}
				onChange={onSelectFloodingSituation}
			/>
			{floodingSituation.value === pageAssets?.request_form_select_yes && (
				<RadioInput
					name={workFollowingFlooding.name}
					label={workFollowingFlooding.label}
					value={workFollowingFlooding.value}
					list={radioSelectionYesNoNotApplicable}
					onChange={onSelectWorkFollowingFlooding}
				/>
			)}
			{(floodingSituation.value === pageAssets?.request_form_select_no ||
				workFollowingFlooding.value !== '') &&
				displayWarningSection(
					pageAssets?.request_form_flooding_info_text_label
				)}

			<Modal
				isOpen={isModalOpen}
				type={'warning'}
				openUsingSetTimeout
				cancelButtonText={pageAssets?.request_form_understood}
				onSetIsOpen={setIsModalOpen}
			>
				<div className={classes.modalContent}>
					{pageAssets?.request_form_flooding_modal_text}
				</div>
			</Modal>
		</>
	)
}

export default FloodingForm
