import { RequestFormProps } from '@utils/request'
import React, { FC } from 'react'
import requestForm from '@components/requestForms/request.form'

const ZoningForm: FC<RequestFormProps> = () => {
	const { pageAssets, displayWarningSection } = requestForm()

	return <>{displayWarningSection(pageAssets?.request_form_zoning_info_text)}</>
}

export default ZoningForm
