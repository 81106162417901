import React, { FC, useEffect, useState } from 'react'
import requestForm from '@components/requestForms/request.form'
import { RequestFormProps, RequestInputs } from '@utils/request'
import RadioInput from '@components/requestForms/inputs/radio.input'
import TextInput from '@components/requestForms/inputs/text.inputs'

const AuthorisedBusinessesForm: FC<RequestFormProps> = ({
	inputs,
	onInputsChange
}) => {
	const { pageAssets, UpdateRequestInputsArray, radioSelectionYesNo } =
		requestForm()

	const [isAnOwnerInput, setIsAnOwnerInput] = useState<RequestInputs>({
		name: 'isAnOwner',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_authorisedBusinesses_isAnOwner_label,
		labelKey: 'request_form_authorisedBusinesses_isAnOwner_label',
		required: false
	})

	const [hasCertificatInput, setHasCertificatInput] = useState<RequestInputs>({
		name: 'hasCertificat',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_authorisedBusinesses_hasCertificat_label,
		labelKey: 'request_form_authorisedBusinesses_hasCertificat_label',
		required: false
	})

	const [certificatNumber, setCertificatNumber] = useState<RequestInputs>({
		name: 'certificatNumber',
		value: '',
		label: pageAssets?.request_form_authorisedBusinesses_certificatNumber_label,
		labelKey: 'request_form_authorisedBusinesses_certificatNumber_label',
		required: false
	})

	const onSelectIsAnOwner = (value: string, valueKey: string) =>
		setIsAnOwnerInput({ ...isAnOwnerInput, value, valueKey })

	const onSelectHasCertificat = (value: string, valueKey: string) => {
		setHasCertificatInput({ ...hasCertificatInput, value, valueKey })

		if (value === pageAssets?.request_form_select_no) {
			setCertificatNumber({ ...certificatNumber, value: '', valueKey: '' })
		}
	}

	const onChangeCertificatNumber = (value: string) =>
		setCertificatNumber({ ...certificatNumber, value })

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, isAnOwnerInput))
	}, [isAnOwnerInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, hasCertificatInput))
	}, [hasCertificatInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, certificatNumber))
	}, [certificatNumber])

	return (
		<>
			<RadioInput
				name={isAnOwnerInput.name}
				label={isAnOwnerInput.label}
				value={isAnOwnerInput.value}
				list={radioSelectionYesNo}
				onChange={onSelectIsAnOwner}
			/>

			<RadioInput
				name={hasCertificatInput.name}
				label={hasCertificatInput.label}
				value={hasCertificatInput.value}
				list={radioSelectionYesNo}
				onChange={onSelectHasCertificat}
			/>

			{hasCertificatInput.value === pageAssets?.request_form_select_yes && (
				<TextInput
					id={certificatNumber.name}
					label={certificatNumber.label}
					value={certificatNumber.value}
					onChange={onChangeCertificatNumber}
				/>
			)}
		</>
	)
}

export default AuthorisedBusinessesForm
