import React, { FC, useEffect, useState } from 'react'
import { RadioList, RequestFormProps, RequestInputs } from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import RadioInput from '@components/requestForms/inputs/radio.input'

const LandRegistryModificationForm: FC<RequestFormProps> = ({
	inputs,
	onInputsChange
}) => {
	const {
		pageAssets,
		radioSelectionYesNo,
		UpdateRequestInputsArray,
		displayWarningSection
	} = requestForm()
	const [requestConcernsInput, setRequestConcernsInput] =
		useState<RequestInputs>({
			name: 'requestConcerns',
			value: '',
			valueKey: '',
			label:
				pageAssets?.request_form_landRegistryModification_requestConcerns_label,
			labelKey: 'request_form_landRegistryModification_requestConcerns_label',
			required: false
		})

	const [landOccupiedInput, setLandOccupiedInput] = useState<RequestInputs>({
		name: 'landOccupied',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_landRegistryModification_landOccupied_label,
		labelKey: 'request_form_landRegistryModification_landOccupied_label',
		required: false
	})

	const [
		preservedFollowingOperationInput,
		setPreservedFollowingOperationInput
	] = useState<RequestInputs>({
		name: 'preservedFollowingOperation',
		value: '',
		valueKey: '',
		label:
			pageAssets?.request_form_landRegistryModification_preservedFollowingOperation_label,
		labelKey:
			'request_form_landRegistryModification_preservedFollowingOperation_label',
		required: false
	})

	const requestConcernsList: RadioList[] = [
		{
			label: pageAssets?.request_form_select_requestConcerns_divisionOfLot,
			value: pageAssets?.request_form_select_requestConcerns_divisionOfLot,
			key: 'request_form_select_requestConcerns_divisionOfLot'
		},
		{
			label: pageAssets?.request_form_select_requestConcerns_unificationOfLot,
			value: pageAssets?.request_form_select_requestConcerns_unificationOfLot,
			key: 'request_form_select_requestConcerns_unificationOfLot'
		}
	]

	const onSelectRequestConcernsInput = (value: string, valueKey: string) => {
		setRequestConcernsInput({ ...requestConcernsInput, value, valueKey })
	}

	const onSelectLandOccupiedInput = (value: string, valueKey: string) => {
		setLandOccupiedInput({ ...landOccupiedInput, value, valueKey })
	}

	const onSelectPreservedFollowingOperationInput = (
		value: string,
		valueKey: string
	) => {
		setPreservedFollowingOperationInput({
			...preservedFollowingOperationInput,
			value,
			valueKey
		})
	}

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, requestConcernsInput))
	}, [requestConcernsInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, landOccupiedInput))
	}, [landOccupiedInput])

	useEffect(() => {
		onInputsChange(
			UpdateRequestInputsArray(inputs, preservedFollowingOperationInput)
		)
	}, [preservedFollowingOperationInput])

	return (
		<>
			<RadioInput
				name={requestConcernsInput.name}
				label={requestConcernsInput.label}
				value={requestConcernsInput.value}
				list={requestConcernsList}
				onChange={onSelectRequestConcernsInput}
			/>

			<RadioInput
				name={landOccupiedInput.name}
				label={landOccupiedInput.label}
				value={landOccupiedInput.value}
				list={radioSelectionYesNo}
				onChange={onSelectLandOccupiedInput}
			/>

			{landOccupiedInput.value === pageAssets?.request_form_select_yes && (
				<RadioInput
					name={preservedFollowingOperationInput.name}
					label={preservedFollowingOperationInput.label}
					value={preservedFollowingOperationInput.value}
					list={radioSelectionYesNo}
					onChange={onSelectPreservedFollowingOperationInput}
				/>
			)}

			{displayWarningSection(
				requestConcernsInput.value ===
					pageAssets?.request_form_select_requestConcerns_divisionOfLot
					? pageAssets?.request_form_requestConcerns_info_text_label
					: pageAssets?.request_form_requestConcerns_info_unified_text_label
			)}
		</>
	)
}

export default LandRegistryModificationForm
