import React, { forwardRef, ForwardRefRenderFunction } from 'react'
import { Colors } from '@utils/css-variables'
import { joinClasses, makeClasses } from '@utils/styles'
import requestForm from '@components/requestForms/request.form'
import Error from '@components/requestForms/inputs/error.input'
import Breakpoints from '@utils/breakpoints'
import { SelectImageList } from '@utils/request'

type Props = {
	id: string
	label: string
	list: SelectImageList[]
	value: string
	showDefaultImgTitle?: boolean
	required?: boolean
	hasError?: boolean
	onChange: (value: string, key: string, label?: string) => void
}

type Classes = {
	root: string
	error: string
	imgCheckboxContainer: string
	imgCheckboxItem: string
	imgCheckboxItemTitle: string
	imgCheckboxItemDesc: string
	imgCheckboxItemChoice: string
	imgCheckboxItemImg: string
	imgCheckboxItemContent: string
	required: string
	contentRoot: string
	noWrap: string
}

const classes: Classes = makeClasses({
	root: {
		marginBottom: '30px'
	},
	imgCheckboxContainer: {
		display: 'flex',
		alignItem: 'center',
		justifyContent: 'space-between',
		maxWidth: '100%',
		width: '100%',
		marginBottom: '30px',
		[Breakpoints.maxWidth('xs')]: {
			flexDirection: 'column',
			justifyContent: 'flex-start'
		}
	},
	imgCheckboxItem: {
		maxWidth: '32%',
		width: '100%',
		[Breakpoints.maxWidth('xs')]: {
			display: 'flex',
			maxWidth: '100%'
		}
	},
	imgCheckboxItemTitle: {
		fontWeight: 'bold',
		lineHeight: '1.62em'
	},
	imgCheckboxItemDesc: {
		fontSize: '14px',
		lineHeight: '18px',
		color: Colors.darkGrey
	},
	imgCheckboxItemChoice: {
		width: '100%',
		height: 'auto',
		border: `4px solid ${Colors.white}`,
		cursor: 'pointer',
		'&.selected': {
			borderColor: Colors.secondary,
			'&:hover': {
				borderColor: Colors.secondary
			}
		},
		'&:hover': {
			borderColor: Colors.primaryConnected
		}
	},
	imgCheckboxItemImg: {
		[Breakpoints.maxWidth('xs')]: {
			maxWidth: '40%',
			width: '100%'
		}
	},
	imgCheckboxItemContent: {
		[Breakpoints.maxWidth('xs')]: {
			maxWidth: '60%',
			width: '100%',
			paddingLeft: '10px',
			marginBottom: '10px'
		}
	},
	error: {
		color: Colors.errorRed
	},
	required: {
		'&::after': {
			content: '"*"',
			color: Colors.ligthPurple,
			fontSize: '1.2em',
			marginLeft: '5px'
		}
	},
	contentRoot: {
		display: 'inline-block',
		paddingRight: '1em'
	},
	noWrap: {
		position: 'relative',
		whiteSpace: 'nowrap',
		'&::after': {
			content: '"*"',
			position: 'absolute',
			top: '45%',
			left: 12,
			transform: 'translateY(-50%)',
			color: Colors.ligthPurple,
			fontSize: '1.2em'
		}
	}
})

const SelectImageInputForwardRef: ForwardRefRenderFunction<
	HTMLImageElement,
	Props
> = (
	{ id, label, list, hasError, showDefaultImgTitle, required, value, onChange },
	ref
) => {
	const { pageAssets } = requestForm()

	const getLabel = () => {
		const hasQuestionMark = label?.endsWith('?')
		if (hasQuestionMark) {
			return (
				<div className={classes.contentRoot}>
					{label?.replace('?', '')}
					<span className={required ? classes.noWrap : ''}>?</span>
				</div>
			)
		} else {
			return <span className={required ? classes.required : ''}>{label}</span>
		}
	}

	return (
		<div id={id} className={classes.root}>
			<label className={hasError ? classes.error : ''}>
				{getLabel()}
				{hasError && <Error hasError={hasError} />}
			</label>
			<div className={classes.imgCheckboxContainer}>
				{list.map((item: SelectImageList, index: number) => {
					if (item.label === '' || item.img === '') {
						return <div className={classes.imgCheckboxItem} key={index}></div>
					}

					return (
						<div className={classes.imgCheckboxItem} key={index}>
							<div className={classes.imgCheckboxItemImg}>
								<img
									className={joinClasses([
										classes.imgCheckboxItemChoice,
										value === item.value ? 'selected' : ''
									])}
									src={item.img}
									onClick={(e) => {
										e.preventDefault()

										onChange(item.value, item.key, item.label)
									}}
									{...(index === 0 ? { ref } : {})}
									tabIndex={1}
								/>
							</div>
							<div className={classes.imgCheckboxItemContent}>
								{showDefaultImgTitle && (
									<p className={classes.imgCheckboxItemTitle}>
										{
											pageAssets?.request_form_potholeStreet_requestType_photo_label
										}{' '}
										{index + 1}
									</p>
								)}
								<p
									className={
										showDefaultImgTitle || item.description
											? classes.imgCheckboxItemDesc
											: classes.imgCheckboxItemTitle
									}
								>
									{item.label}
									{item.description && <>{item.description}</>}
								</p>
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}

const SelectImageInput = forwardRef(SelectImageInputForwardRef)

export default SelectImageInput
