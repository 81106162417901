import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import { RadioList, RequestFormProps, RequestInputs } from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import RadioInput from '@components/requestForms/inputs/radio.input'

const LandscapingAndMaintenanceForm: FC<RequestFormProps> = ({
	inputs,
	errors,
	onInputsChange,
	onFixError
}) => {
	const {
		pageAssets,
		radioListYesNo,
		radioSelectionYesNo,
		UpdateRequestInputsArray,
		UpdateRequestInputsErrorsArray,
		displayWarningSection
	} = requestForm()

	const [landNearWaterInput, setLandNearWaterInput] = useState<RequestInputs>({
		name: 'waterside',
		value: '',
		valueKey: '',
		label:
			pageAssets?.request_form_landscapingAndMaintenance_landNearWater_label,
		labelKey: 'request_form_landscapingAndMaintenance_landNearWater_label',
		required: true,
		ref: useRef<HTMLInputElement>(null)
	})

	const [changeHeightLandInput, setChangeHeightLandInput] =
		useState<RequestInputs>({
			name: 'changeHeightLand',
			value: '',
			valueKey: '',
			label:
				pageAssets?.request_form_landscapingAndMaintenance_changeHeightLand_label,
			labelKey: 'request_form_landscapingAndMaintenance_changeHeightLand_label',
			required: false
		})

	const onSelectLandNearWaterInput = (value: string, valueKey: string) => {
		setLandNearWaterInput({ ...landNearWaterInput, value, valueKey })
		onFixError(UpdateRequestInputsErrorsArray(errors, landNearWaterInput.name))
	}

	const onSelectChangeHeightLandInput = (value: string, valueKey: string) =>
		setChangeHeightLandInput({ ...changeHeightLandInput, value, valueKey })

	const changeHeightLandList: RadioList[] = [
		...radioSelectionYesNo,
		{
			label: pageAssets?.select_changeHeightLand_notApplicable_doNotKnow,
			value: pageAssets?.select_changeHeightLand_notApplicable_doNotKnow,
			key: 'select_changeHeightLand_notApplicable_doNotKnow'
		}
	]

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, landNearWaterInput))
	}, [landNearWaterInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, changeHeightLandInput))
	}, [changeHeightLandInput])

	return (
		<>
			<RadioInput
				name={landNearWaterInput.name}
				label={landNearWaterInput.label}
				value={landNearWaterInput.value}
				list={radioListYesNo}
				required
				onChange={onSelectLandNearWaterInput}
				hasError={errors.includes(landNearWaterInput.name)}
				ref={landNearWaterInput.ref as RefObject<HTMLInputElement>}
			/>

			<RadioInput
				name={changeHeightLandInput.name}
				label={changeHeightLandInput.label}
				value={changeHeightLandInput.value}
				list={changeHeightLandList}
				onChange={onSelectChangeHeightLandInput}
			/>

			{displayWarningSection(
				pageAssets?.request_form_landscaping_maintenace_info_text_label
			)}
		</>
	)
}

export default LandscapingAndMaintenanceForm
