import React, { FC, useState, useEffect } from 'react'
import { RequestFormProps, RequestInputs } from '@utils/request'
import RadioInput from '@components/requestForms/inputs/radio.input'
import requestForm from '@components/requestForms/request.form'

const ConstructionStandardsFloodRiskForm: FC<RequestFormProps> = ({
	inputs,
	onInputsChange
}) => {
	const { pageAssets, UpdateRequestInputsArray, radioSelectionYesNo } =
		requestForm()

	const [floodedInput, setFloodedInput] = useState<RequestInputs>({
		name: 'flooded',
		value: '',
		valueKey: '',
		label:
			pageAssets?.request_form_constructionStandardsFloodRisk_flooded_label,
		labelKey: 'request_form_constructionStandardsFloodRisk_flooded_label',
		required: false
	})

	const [floodedWorkInput, setFloodedWorkInput] = useState<RequestInputs>({
		name: 'workFollowingflood',
		value: '',
		valueKey: '',
		label:
			pageAssets?.request_form_constructionStandardsFloodRisk_workFollowingflood_label,
		labelKey:
			'request_form_constructionStandardsFloodRisk_workFollowingflood_label',
		required: false
	})

	const onFloodedChanged = (value: string, valueKey: string) =>
		setFloodedInput({ ...floodedInput, value, valueKey })

	const onFloodedWorkChanged = (value: string, valueKey: string) =>
		setFloodedWorkInput({ ...floodedWorkInput, value, valueKey })

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, floodedInput))
	}, [floodedInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, floodedWorkInput))
	}, [floodedWorkInput])

	return (
		<>
			<RadioInput
				name={floodedInput.name}
				label={floodedInput.label}
				value={floodedInput.value}
				list={radioSelectionYesNo}
				required={floodedInput.required}
				onChange={onFloodedChanged}
			/>

			<RadioInput
				name={floodedWorkInput.name}
				label={floodedWorkInput.label}
				value={floodedWorkInput.value}
				list={radioSelectionYesNo}
				required={floodedWorkInput.required}
				onChange={onFloodedWorkChanged}
			/>
		</>
	)
}

export default ConstructionStandardsFloodRiskForm
