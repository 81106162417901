import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import requestForm from '@components/requestForms/request.form'
import {
	RadioList,
	RequestFormProps,
	RequestInputs,
	SelectList
} from '@utils/request'
import config from '@utils/config'
import { useAppDispatch } from '@services/store'
import { Request } from 'typings/shared'
import { populateRequestModelState } from '@services/store/request'
import SelectInput from '@components/requestForms/inputs/select.inputs'
import RadioInput from '@components/requestForms/inputs/radio.input'
import { REQUESTS_IDS, REQUEST_CONFIGS_KEYS } from '@services/constants'

const BinLostStoleIncorrectlyReplaceForm: FC<RequestFormProps> = ({
	inputs,
	errors,
	onInputsChange,
	onFixError
}) => {
	const {
		pageAssets,
		requests,
		displayWarningSection,
		UpdateRequestInputsErrorsArray,
		UpdateRequestInputsArray,
		filterMultipleRequests
	} = requestForm()

	const dispatch = useAppDispatch()

	const [isBinImproperlyReplaced, setIsBinImproperlyReplaced] =
		useState<boolean>(false)
	const [isBinStolen, setIsBinStolen] = useState<boolean>(false)
	const [requestTypeList, setRequestTypeList] = useState<SelectList[]>([])

	const [requestTypeInput, setRequestTypeInput] = useState<RequestInputs>({
		name: 'requestType',
		value: '',
		label:
			pageAssets?.request_form_binLostStoleIncorrectlyReplace_requestType_label,
		labelKey: 'request_form_binLostStoleIncorrectlyReplace_requestType_label',
		required: true,
		ref: useRef<HTMLSelectElement>(null)
	})
	const [containerProvidedInput, setContainerProvidedInput] =
		useState<RequestInputs>({
			name: 'temp',
			temporaryName: 'temp',
			value: '',
			label:
				pageAssets?.request_form_binLostStoleIncorrectlyReplace_containerProvided_label,
			labelKey:
				'request_form_binLostStoleIncorrectlyReplace_containerProvided_label',
			required: true,
			ref: useRef<HTMLSelectElement>(null)
		})
	const [onlyAddressSituationInput, setOnlyAddressSituationInput] =
		useState<RequestInputs>({
			name: 'onlyAddressSituation',
			value: '',
			label:
				pageAssets?.request_form_binLostStoleIncorrectlyReplace_onlyAddressSituation_label,
			labelKey:
				'request_form_binLostStoleIncorrectlyReplace_onlyAddressSituation_label',
			required: false
		})
	const [situationRecurrentInput, setSituationRecurrentInput] =
		useState<RequestInputs>({
			name: 'situationRecurrent',
			value: '',
			label:
				pageAssets?.request_form_binLostStoleIncorrectlyReplace_situationRecurrent_label,
			labelKey:
				'request_form_binLostStoleIncorrectlyReplace_situationRecurrent_label',
			required: false
		})

	const binTypeList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.select_binType_garbage,
			value: isBinStolen
				? REQUEST_CONFIGS_KEYS.binType.brokenBinGarbage
				: REQUEST_CONFIGS_KEYS.binType.garbage,
			key: 'select_binType_garbage'
		},
		{
			label: pageAssets?.select_binType_recycling,
			value: REQUEST_CONFIGS_KEYS.binType.recycling,
			key: 'select_binType_recycling'
		},
		{
			label: pageAssets?.select_binType_compost,
			value: REQUEST_CONFIGS_KEYS.binType.compost,
			key: 'select_binType_compost'
		}
	]

	const onlyAddressSituationList: RadioList[] = [
		{
			label: pageAssets?.request_form_select_yes,
			value: pageAssets?.request_form_select_yes,
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets?.select_no_neighbors_affected,
			value: pageAssets?.select_no_neighbors_affected,
			key: 'select_no_neighbors_affected'
		},
		{
			label: pageAssets?.request_form_select_dont_know,
			value: pageAssets?.request_form_select_dont_know,
			key: 'request_form_select_dont_know'
		}
	]

	const situationRecurrentList: RadioList[] = [
		{
			label: pageAssets?.request_form_select_yes,
			value: pageAssets?.request_form_select_yes,
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets?.select_no_fisrt_time,
			value: pageAssets?.select_no_fisrt_time,
			key: 'select_no_fisrt_time'
		}
	]

	const onSelectRequestTypeInput = (value: string, valueKey: string) => {
		setRequestTypeInput({ ...requestTypeInput, value, valueKey })
		setIsBinImproperlyReplaced(
			valueKey ===
				config.request.create.binLostStoleIncorrectlyReplace
					.binImproperlyReplacedKey
		)
		setIsBinStolen(
			valueKey ===
				config.request.create.binLostStoleIncorrectlyReplace.binLostOrStolen
		)
		onFixError(UpdateRequestInputsErrorsArray(errors, requestTypeInput.name))

		switch (value) {
			case REQUESTS_IDS.binIncorrectlyReplaced:
				setContainerProvidedInput({
					...containerProvidedInput,
					name: 'containerCollection'
				})
				break
			case REQUESTS_IDS.binLostOrStolen:
				setContainerProvidedInput({
					...containerProvidedInput,
					name: 'containerProvided'
				})
				break
			default:
				setContainerProvidedInput({ ...containerProvidedInput, name: 'temp' })
				break
		}

		const requestSelected: Request | undefined = requests.find(
			(request) => request.requestId === value
		)
		let title: string = ''

		if (value !== '' && requestSelected) {
			title = requestSelected.title
		}

		dispatch(
			populateRequestModelState({
				title,
				typeId: value
			})
		)
	}

	const onSelectContainerProvidedInput = (value: string, valueKey: string) => {
		setContainerProvidedInput({ ...containerProvidedInput, value, valueKey })
		onFixError(
			UpdateRequestInputsErrorsArray(errors, containerProvidedInput.name)
		)
	}

	const onSelectOnlyAddressSituationInput = (
		value: string,
		valueKey: string
	) => {
		setOnlyAddressSituationInput({
			...onlyAddressSituationInput,
			value,
			valueKey
		})
	}

	const onSelectSituationRecurrentInput = (value: string, valueKey: string) => {
		setSituationRecurrentInput({ ...situationRecurrentInput, value, valueKey })
	}

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, requestTypeInput))
	}, [requestTypeInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, containerProvidedInput))
	}, [containerProvidedInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, onlyAddressSituationInput))
	}, [onlyAddressSituationInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, situationRecurrentInput))
	}, [situationRecurrentInput])

	useEffect(() => {
		const list: SelectList[] = filterMultipleRequests(
			config.request.create.binLostStoleIncorrectlyReplace
				.requestTypeListIncludes
		)

		setRequestTypeList(list)
	}, [])

	return (
		<>
			<SelectInput
				id={requestTypeInput.name}
				label={requestTypeInput.label}
				value={requestTypeInput.value}
				list={requestTypeList}
				required
				hasError={errors.includes(requestTypeInput.name)}
				onChange={onSelectRequestTypeInput}
				ref={requestTypeInput.ref as RefObject<HTMLSelectElement>}
			/>

			<SelectInput
				id={containerProvidedInput.name}
				label={containerProvidedInput.label}
				value={containerProvidedInput.value}
				list={binTypeList}
				required
				hasError={errors.includes(containerProvidedInput.name)}
				onChange={onSelectContainerProvidedInput}
				ref={containerProvidedInput.ref as RefObject<HTMLSelectElement>}
			/>

			<RadioInput
				name={onlyAddressSituationInput.name}
				label={onlyAddressSituationInput.label}
				value={onlyAddressSituationInput.value}
				list={onlyAddressSituationList}
				onChange={onSelectOnlyAddressSituationInput}
			/>

			{isBinStolen && (
				<RadioInput
					name={situationRecurrentInput.name}
					label={situationRecurrentInput.label}
					value={situationRecurrentInput.value}
					list={situationRecurrentList}
					onChange={onSelectSituationRecurrentInput}
				/>
			)}

			{isBinImproperlyReplaced &&
				displayWarningSection(
					pageAssets?.request_form_binLostStoleIncorrectlyReplace_notification
				)}
		</>
	)
}

export default BinLostStoleIncorrectlyReplaceForm
