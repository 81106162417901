import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import { RequestFormProps, RequestInputs, SelectList } from '@utils/request'
import SelectInput from '@components/requestForms/inputs/select.inputs'
import requestForm from '@components/requestForms/request.form'
import RadioInput from '@components/requestForms/inputs/radio.input'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'

const MiscellaneousEquipmentForm: FC<RequestFormProps> = ({
	inputs,
	errors,
	onInputsChange,
	onFixError
}) => {
	const {
		pageAssets,
		UpdateRequestInputsArray,
		UpdateRequestInputsErrorsArray,
		radioSelectionYesNoDoNotKnow,
		radioListYesNo
	} = requestForm()

	const [equipmentInput, setEquipmentInput] = useState<RequestInputs>({
		name: 'equipmentType',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_miscellaneousEquipment_equipmentType_label,
		labelKey: 'request_form_miscellaneousEquipment_equipmentType_label',
		required: true,
		ref: useRef<HTMLSelectElement>(null)
	})

	const [propertyInput, setPropertyInput] = useState<RequestInputs>({
		name: 'propertyType',
		value: REQUEST_CONFIGS_KEYS.no,
		valueKey: 'request_form_select_no',
		label: pageAssets?.request_form_miscellaneousEquipment_propertyType_label,
		labelKey: 'request_form_miscellaneousEquipment_propertyType_label',
		required: true,
		ref: useRef<HTMLInputElement>(null)
	})

	const [carShelterFQInput, setCarShelterFQInput] = useState<RequestInputs>({
		name: 'carShelterTypeFirstQuestion',
		value: '',
		valueKey: '',
		label:
			pageAssets?.request_form_miscellaneousEquipment_carShelterTypeFirstQuestion_label,
		labelKey:
			'request_form_miscellaneousEquipment_carShelterTypeFirstQuestion_label',
		required: false
	})

	const [carShelterSQInput, setCarShelterSQInput] = useState<RequestInputs>({
		name: 'carShelterTypeSecondQuestion',
		value: '',
		valueKey: '',
		label:
			pageAssets?.request_form_miscellaneousEquipment_carShelterTypeSecondQuestion_label,
		labelKey:
			'request_form_miscellaneousEquipment_carShelterTypeSecondQuestion_label',
		required: false
	})

	const equipmentType: SelectList[] = [
		{
			value: '',
			label: '',
			key: ''
		},
		{
			value: REQUEST_CONFIGS_KEYS.equipment.donationBox,
			label: pageAssets?.request_form_select_donation_box,
			key: 'request_form_select_donation_box'
		},
		{
			value: REQUEST_CONFIGS_KEYS.equipment.heatPump,
			label: pageAssets?.request_form_select_heat_pump,
			key: 'request_form_select_heat_pump'
		},
		{
			value: REQUEST_CONFIGS_KEYS.equipment.foyer,
			label: pageAssets?.request_form_select_foyer,
			key: 'request_form_select_foyer'
		},
		{
			value: REQUEST_CONFIGS_KEYS.equipment.cookingAppliance,
			label: pageAssets?.request_form_select_cooking_appliance,
			key: 'request_form_select_cooking_appliance'
		},
		{
			value: REQUEST_CONFIGS_KEYS.equipment.privacyTrellis,
			label: pageAssets?.request_form_select_privacy_trellis,
			key: 'request_form_select_privacy_trellis'
		},
		{
			value: REQUEST_CONFIGS_KEYS.equipment.carShelter,
			label: pageAssets?.request_form_select_car_shelters,
			key: 'request_form_select_car_shelters'
		},
		{
			value: REQUEST_CONFIGS_KEYS.equipment.other,
			label: pageAssets?.request_form_select_others,
			key: 'request_form_select_others'
		}
	]

	const changePropertyAttr = (value: string) => {
		if (value === REQUEST_CONFIGS_KEYS.equipment.donationBox) {
			return setPropertyInput({ ...propertyInput, value: '', valueKey: '' })
		}

		return setPropertyInput({
			...propertyInput,
			value: REQUEST_CONFIGS_KEYS.no,
			valueKey: 'request_form_select_no'
		})
	}

	const changeCarShelterQuestions = (value: string) => {
		if (value !== pageAssets?.request_form_select_car_shelters) {
			setCarShelterFQInput({ ...carShelterFQInput, value: '', valueKey: '' })
			setCarShelterSQInput({ ...carShelterSQInput, value: '', valueKey: '' })
		}
	}

	const onSelectEquipment = (value: string, valueKey: string) => {
		changePropertyAttr(value)
		changeCarShelterQuestions(value)
		setEquipmentInput({ ...equipmentInput, value, valueKey })
		onFixError(UpdateRequestInputsErrorsArray(errors, equipmentInput.name))
	}

	const onSelectProperty = (value: string, valueKey: string) => {
		setPropertyInput({ ...propertyInput, value, valueKey })
		onFixError(UpdateRequestInputsErrorsArray(errors, propertyInput.name))
	}

	const onSelectCarShelterFisrtQuestion = (value: string, valueKey: string) =>
		setCarShelterFQInput({ ...carShelterFQInput, value, valueKey })

	const onSelectCarShelterSecondQuestion = (value: string, valueKey: string) =>
		setCarShelterSQInput({ ...carShelterSQInput, value, valueKey })

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, equipmentInput))
	}, [equipmentInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, propertyInput))
	}, [propertyInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, carShelterFQInput))
	}, [carShelterFQInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, carShelterSQInput))
	}, [carShelterSQInput])

	return (
		<>
			<SelectInput
				id={equipmentInput.name}
				label={equipmentInput.label}
				value={equipmentInput.value}
				list={equipmentType}
				hasError={errors.includes(equipmentInput.name)}
				required
				onChange={onSelectEquipment}
				ref={equipmentInput.ref as RefObject<HTMLSelectElement>}
			/>

			{equipmentInput.value === REQUEST_CONFIGS_KEYS.equipment.donationBox && (
				<RadioInput
					name={propertyInput.name}
					label={propertyInput.label}
					list={radioListYesNo}
					value={propertyInput.value}
					required
					hasError={errors.includes(propertyInput.name)}
					onChange={onSelectProperty}
					ref={propertyInput.ref as RefObject<HTMLInputElement>}
				/>
			)}

			{equipmentInput.value === REQUEST_CONFIGS_KEYS.equipment.carShelter && (
				<>
					<RadioInput
						name={carShelterFQInput.name}
						label={carShelterFQInput.label}
						value={carShelterFQInput.value}
						list={radioSelectionYesNoDoNotKnow}
						onChange={onSelectCarShelterFisrtQuestion}
					/>
					<RadioInput
						name={carShelterSQInput.name}
						label={carShelterSQInput.label}
						value={carShelterSQInput.value}
						list={radioSelectionYesNoDoNotKnow}
						onChange={onSelectCarShelterSecondQuestion}
					/>
				</>
			)}
		</>
	)
}

export default MiscellaneousEquipmentForm
