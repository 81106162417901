import React, { FC, useEffect, useState } from 'react'
import { RequestFormProps, RequestInputs, SelectList } from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import SelectInput from '@components/requestForms/inputs/select.inputs'
import TextAreaInput from '@components/requestForms/inputs/textarea.inputs'
import RadioInput from '@components/requestForms/inputs/radio.input'

const RenovationConstructionBuildingForm: FC<RequestFormProps> = ({
	inputs,
	onInputsChange
}) => {
	const { pageAssets, radioSelectionYesNo, UpdateRequestInputsArray } =
		requestForm()

	const [workTypeInput, setWorkTypeInput] = useState<RequestInputs>({
		name: 'workType',
		value: '',
		valueKey: '',
		label:
			pageAssets?.request_form_renovationConstructionBuilding_workType_label,
		labelKey: 'request_form_renovationConstructionBuilding_workType_label',
		required: false
	})

	const [enlargementInput, setEnlargementInput] = useState<RequestInputs>({
		name: 'enlargement',
		value: '',
		valueKey: '',
		label:
			pageAssets?.request_form_renovationConstructionBuilding_enlargement_label,
		labelKey: 'request_form_renovationConstructionBuilding_enlargement_label',
		required: false
	})

	const [workCostInput, setCostInput] = useState<RequestInputs>({
		name: 'workCost',
		value: '',
		label:
			pageAssets?.request_form_renovationConstructionBuilding_workCost_label,
		labelKey: 'request_form_renovationConstructionBuilding_workCost_label',
		required: false
	})

	const [typeOfWorkInput, setTypeOfWorkInput] = useState<RequestInputs>({
		name: 'typeOfWork',
		value: '',
		label:
			pageAssets?.request_form_renovationConstructionBuilding_typeOfWork_label,
		labelKey: 'request_form_renovationConstructionBuilding_typeOfWork_label',
		required: false
	})

	const workTypeSelection: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.request_form_select_enlargement,
			value: pageAssets?.request_form_select_enlargement,
			key: 'request_form_select_enlargement'
		},
		{
			label: pageAssets?.request_form_select_renovations,
			value: pageAssets?.request_form_select_renovations,
			key: 'request_form_select_renovations'
		},
		{
			label: pageAssets?.request_form_select_new_construction,
			value: pageAssets?.request_form_select_new_construction,
			key: 'request_form_select_new_construction'
		}
	]

	const onChangeWorkTypeInput = (value: string, valueKey: string) => {
		setWorkTypeInput({ ...workTypeInput, value, valueKey })
		setEnlargementInput({ ...enlargementInput, value: '', valueKey: '' })
		setTypeOfWorkInput({ ...typeOfWorkInput, value: '', valueKey: '' })
		setCostInput({ ...workCostInput, value: '', valueKey: '' })
	}

	const onChangeEnlargementInput = (value: string, valueKey: string) =>
		setEnlargementInput({ ...enlargementInput, value, valueKey })

	const onChangeCostInput = (value: string) =>
		setCostInput({ ...workCostInput, value })

	const onChangeTypeOfWorkInput = (value: string) => {
		setTypeOfWorkInput({ ...typeOfWorkInput, value })
	}

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, workTypeInput))
	}, [workTypeInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, enlargementInput))
	}, [enlargementInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, workCostInput))
	}, [workCostInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, typeOfWorkInput))
	}, [typeOfWorkInput])

	return (
		<>
			<SelectInput
				id={workTypeInput.name}
				value={workTypeInput.value}
				label={workTypeInput.label}
				list={workTypeSelection}
				onChange={onChangeWorkTypeInput}
			/>

			{workTypeInput.value === pageAssets?.request_form_select_enlargement && (
				<RadioInput
					name={enlargementInput.name}
					label={enlargementInput.label}
					value={enlargementInput.value}
					list={radioSelectionYesNo}
					onChange={onChangeEnlargementInput}
				/>
			)}

			{(workTypeInput.value === pageAssets?.request_form_select_renovations ||
				workTypeInput.value ===
					pageAssets?.request_form_select_new_construction) && (
				<TextAreaInput
					id={typeOfWorkInput.name}
					value={typeOfWorkInput.value}
					label={typeOfWorkInput.label}
					onChange={onChangeTypeOfWorkInput}
					maxCharacters={100}
				/>
			)}

			{workTypeInput.value === pageAssets?.request_form_select_renovations && (
				<TextAreaInput
					id={workCostInput.name}
					value={workCostInput.value}
					label={workCostInput.label}
					onChange={onChangeCostInput}
					maxCharacters={50}
				/>
			)}
		</>
	)
}

export default RenovationConstructionBuildingForm
