import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import {
	RequestFormProps,
	RequestInputs,
	SelectList,
	RadioList
} from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import SelectInput from '@components/requestForms/inputs/select.inputs'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'
import RadioInput from '@components/requestForms/inputs/radio.input'

const { placeType, locationPlace, property, uncleanOrigin } =
	REQUEST_CONFIGS_KEYS

type IPlaceType = typeof placeType
type ILocationPlace = typeof locationPlace
type IPropertyType = typeof property
type IUncleanOrigin = typeof uncleanOrigin

const VisualPollutionForm: FC<RequestFormProps> = ({
	inputs,
	errors,
	onInputsChange,
	onFixError
}) => {
	const {
		pageAssets,
		radioSelectionYesNoDoNotKnow,
		UpdateRequestInputsArray,
		UpdateRequestInputsErrorsArray,
		displayWarningSection
	} = requestForm()

	const [reportedProblemList, setReportedProblemList] = useState<SelectList[]>(
		[]
	)
	const [showSecondQuestion, setShowSecondQuestion] = useState<boolean>(false)
	const [reportConcernVisualInput, setreportConcernVisualInput] =
		useState<RequestInputs>({
			name: 'reportConcernVisual',
			value: '',
			valueKey: '',
			label: pageAssets?.request_form_visualPollution_reportConcernVisual_label,
			labelKey: 'request_form_visualPollution_reportConcernVisual_label',
			required: true,
			ref: useRef<HTMLSelectElement>(null)
		})
	const [placeTypeInput, setPlaceTypeInput] = useState<RequestInputs>({
		name: 'placeType',
		value: '',
		label: '',
		labelKey: '',
		required: false
	})
	const [locationPlaceInput, setLocationPlaceInput] = useState<RequestInputs>({
		name: 'locationPlace',
		value: '',
		label: '',
		labelKey: '',
		required: false
	})
	const [propertyTypeInput, setPropertyTypeInput] = useState<RequestInputs>({
		name: 'propertyType',
		value: '',
		label: '',
		labelKey: '',
		required: false
	})
	const [uncleanOriginInput, setUncleanOriginInput] = useState<RequestInputs>({
		name: 'uncleanOrigin',
		value: '',
		label: '',
		labelKey: '',
		required: false
	})
	const [reportedProblemInput, setReportedProblemInput] =
		useState<RequestInputs>({
			name: 'reportedProblem',
			value: '',
			valueKey: '',
			label: pageAssets?.request_form_visualPollution_reportedProblem_label,
			labelKey: 'request_form_visualPollution_reportedProblem_label',
			required: false
		})
	const [typeOfTrafficInput, setTypeOfTrafficInput] = useState<RequestInputs>({
		name: 'typeOfTraffic',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_visualPollution_typeOfTraffic_label,
		labelKey: 'request_form_visualPollution_typeOfTraffic_label',
		required: false
	})
	const [situationRecurrentInput, setSituationRecurrentInput] =
		useState<RequestInputs>({
			name: 'situationRecurrent',
			value: '',
			valueKey: '',
			label: pageAssets?.request_form_visualPollution_situationRecurrent_label,
			labelKey: 'request_form_visualPollution_situationRecurrent_label',
			required: false
		})
	const [workInProgressInput, setWorkInProgressInput] = useState<RequestInputs>(
		{
			name: 'workInProgress',
			value: '',
			valueKey: '',
			label: pageAssets?.request_form_visualPollution_workInProgress_label,
			labelKey: 'request_form_visualPollution_workInProgress_label',
			required: false
		}
	)

	const reportConcernVisualList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.request_form_select_visualPoluttion_street,
			value: pageAssets?.request_form_select_visualPoluttion_street,
			key: 'request_form_select_visualPoluttion_street'
		},
		{
			label: pageAssets?.request_form_select_visualPoluttion_sidewalk,
			value: pageAssets?.request_form_select_visualPoluttion_sidewalk,
			key: 'request_form_select_visualPoluttion_sidewalk'
		},
		{
			label: pageAssets?.request_form_select_visualPoluttion_bicycleLane,
			value: pageAssets?.request_form_select_visualPoluttion_bicycleLane,
			key: 'request_form_select_visualPoluttion_bicycleLane'
		},
		{
			label: pageAssets?.request_form_select_visualPoluttion_park,
			value: pageAssets?.request_form_select_visualPoluttion_park,
			key: 'request_form_select_visualPoluttion_park'
		},
		{
			label: pageAssets?.request_form_select_visualPoluttion_privateLand,
			value: pageAssets?.request_form_select_visualPoluttion_privateLand,
			key: 'request_form_select_visualPoluttion_privateLand'
		},
		{
			label: pageAssets?.request_form_select_visualPoluttion_balcony,
			value: pageAssets?.request_form_select_visualPoluttion_balcony,
			key: 'request_form_select_visualPoluttion_balcony'
		},
		{
			label: pageAssets?.request_form_select_visualPoluttion_privateBuilding,
			value: pageAssets?.request_form_select_visualPoluttion_privateBuilding,
			key: 'request_form_select_visualPoluttion_privateBuilding'
		},
		{
			label: pageAssets?.request_form_select_visualPoluttion_cityBuilding,
			value: pageAssets?.request_form_select_visualPoluttion_cityBuilding,
			key: 'request_form_select_visualPoluttion_cityBuilding'
		},
		{
			label: pageAssets?.request_form_select_visualPoluttion_other,
			value: pageAssets?.request_form_select_visualPoluttion_other,
			key: 'request_form_select_visualPoluttion_other'
		}
	]

	const reportConcertVisualParkAndLandList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.request_form_select_visualPoluttion_accumulation,
			value: pageAssets?.request_form_select_visualPoluttion_accumulation,
			key: 'request_form_select_visualPoluttion_accumulation'
		},
		{
			label: pageAssets?.request_form_select_visualPoluttion_stagnantWater,
			value: pageAssets?.request_form_select_visualPoluttion_stagnantWater,
			key: 'request_form_select_visualPoluttion_stagnantWater'
		}
	]

	const reportConcertVisualBuildingList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.request_form_select_visualPoluttion_accumulation,
			value: pageAssets?.request_form_select_visualPoluttion_accumulation,
			key: 'request_form_select_visualPoluttion_accumulation'
		},
		{
			label: pageAssets?.request_form_select_visualPoluttion_mold,
			value: pageAssets?.request_form_select_visualPoluttion_mold,
			key: 'request_form_select_visualPoluttion_mold'
		},
		{
			label: pageAssets?.request_form_select_visualPoluttion_unsanitary,
			value: pageAssets?.request_form_select_visualPoluttion_unsanitary,
			key: 'request_form_select_visualPoluttion_unsanitary'
		}
	]

	const typeOfTrafficList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.request_form_select_visualPoluttion_street,
			value: pageAssets?.request_form_select_visualPoluttion_street,
			key: 'request_form_select_visualPoluttion_street'
		},
		{
			label: pageAssets?.request_form_select_visualPoluttion_boulevard,
			value: pageAssets?.request_form_select_visualPoluttion_boulevard,
			key: 'request_form_select_visualPoluttion_boulevard'
		},
		{
			label: pageAssets?.request_form_select_visualPoluttion_freeway,
			value: pageAssets?.request_form_select_visualPoluttion_freeway,
			key: 'request_form_select_visualPoluttion_freeway'
		}
	]

	const situationRecurrentList: RadioList[] = [
		{
			label: pageAssets?.request_form_select_yes,
			value: pageAssets?.request_form_select_yes,
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets?.request_form_select_no_fistTime,
			value: pageAssets?.request_form_select_no_fistTime,
			key: 'request_form_select_no_fistTime'
		}
	]

	const onSelectReportConcernVisualInput = (
		value: string,
		valueKey: string
	) => {
		setreportConcernVisualInput({
			...reportConcernVisualInput,
			value,
			valueKey
		})
		onFixError(
			UpdateRequestInputsErrorsArray(errors, reportConcernVisualInput.name)
		)

		let secondQuestionList: SelectList[] = []
		let doShowSecondQuestion: boolean = false

		switch (value) {
			case pageAssets?.request_form_select_visualPoluttion_street:
				onChangeReportConcertInputs('street', 'outside', 'city', 'accrual')
				break
			case pageAssets?.request_form_select_visualPoluttion_sidewalk:
				onChangeReportConcertInputs('sidewalk', 'outside', 'city', 'accrual')
				break
			case pageAssets?.request_form_select_visualPoluttion_bicycleLane:
				onChangeReportConcertInputs('bicycleLane', 'outside', 'city', 'accrual')
				break
			case pageAssets?.request_form_select_visualPoluttion_park:
				onChangeReportConcertInputs('park', 'outside', 'city')
				secondQuestionList = reportConcertVisualParkAndLandList
				doShowSecondQuestion = true
				break
			case pageAssets?.request_form_select_visualPoluttion_privateLand:
				onChangeReportConcertInputs('land', 'outside', 'private')
				secondQuestionList = reportConcertVisualParkAndLandList
				doShowSecondQuestion = true
				break
			case pageAssets?.request_form_select_visualPoluttion_balcony:
				onChangeReportConcertInputs(
					'balcony',
					'outside',
					'private',
					'unsanitary'
				)
				break
			case pageAssets?.request_form_select_visualPoluttion_privateBuilding:
				onChangeReportConcertInputs('building', 'interior', 'private')
				secondQuestionList = reportConcertVisualBuildingList
				doShowSecondQuestion = true
				break
			case pageAssets?.request_form_select_visualPoluttion_cityBuilding:
				onChangeReportConcertInputs('building', 'interior', 'city')
				secondQuestionList = reportConcertVisualBuildingList
				doShowSecondQuestion = true
				break
			case pageAssets?.request_form_select_visualPoluttion_other:
				onChangeReportConcertInputs('other')
				break
			default:
				onChangeReportConcertInputs()
				break
		}

		if (!doShowSecondQuestion) {
			onFixError(
				UpdateRequestInputsErrorsArray(errors, reportedProblemInput.name)
			)
		}

		setReportedProblemInput({
			...reportedProblemInput,
			value: '',
			valueKey: '',
			required: doShowSecondQuestion
		})
		setReportedProblemList(secondQuestionList)
		setShowSecondQuestion(doShowSecondQuestion)
	}

	const onChangeReportConcertInputs = (
		placeKey?: keyof IPlaceType,
		locationKey?: keyof ILocationPlace,
		propertyKey?: keyof IPropertyType,
		uncleanKey?: keyof IUncleanOrigin
	) => {
		setPlaceTypeInput({
			...placeTypeInput,
			value: placeKey ? placeType[placeKey] : '',
			required: placeKey ? true : false
		})
		setLocationPlaceInput({
			...locationPlaceInput,
			value: locationKey ? locationPlace[locationKey] : '',
			required: locationKey ? true : false
		})
		setPropertyTypeInput({
			...propertyTypeInput,
			value: propertyKey ? property[propertyKey] : '',
			required: propertyKey ? true : false
		})
		setUncleanOriginInput({
			...uncleanOriginInput,
			value: uncleanKey ? uncleanOrigin[uncleanKey] : '',
			required: uncleanKey ? true : false
		})
	}

	const onSelectReportedProblemInput = (value: string, valueKey: string) => {
		setReportedProblemInput({ ...reportedProblemInput, value, valueKey })

		let doFixError: boolean = true

		switch (value) {
			case pageAssets?.request_form_select_visualPoluttion_accumulation:
				setUncleanOriginInput({
					...uncleanOriginInput,
					value: uncleanOrigin.accrual,
					required: true
				})
				break
			case pageAssets?.request_form_select_visualPoluttion_stagnantWater:
				setUncleanOriginInput({
					...uncleanOriginInput,
					value: uncleanOrigin.standingWater,
					required: true
				})
				break
			case pageAssets?.request_form_select_visualPoluttion_mold:
				setUncleanOriginInput({
					...uncleanOriginInput,
					value: uncleanOrigin.mold,
					required: true
				})
				break
			case pageAssets?.request_form_select_visualPoluttion_unsanitary:
				setUncleanOriginInput({
					...uncleanOriginInput,
					value: uncleanOrigin.unsanitary,
					required: true
				})
				break
			default:
				setUncleanOriginInput({
					...uncleanOriginInput,
					value: '',
					required: false
				})
				doFixError = false
				break
		}

		if (doFixError) {
			onFixError(
				UpdateRequestInputsErrorsArray(errors, reportedProblemInput.name)
			)
		}
	}

	const onSelectTypeOfTrafficInput = (value: string, valueKey: string) => {
		setTypeOfTrafficInput({ ...typeOfTrafficInput, value, valueKey })
	}

	const onSelectSituationRecurrentInput = (value: string, valueKey: string) => {
		setSituationRecurrentInput({ ...situationRecurrentInput, value, valueKey })
	}

	const onSelectWorkInProgressInput = (value: string, valueKey: string) => {
		setWorkInProgressInput({ ...workInProgressInput, value, valueKey })
	}

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, reportConcernVisualInput))
	}, [reportConcernVisualInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, placeTypeInput))
	}, [placeTypeInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, locationPlaceInput))
	}, [locationPlaceInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, propertyTypeInput))
	}, [propertyTypeInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, uncleanOriginInput))
	}, [uncleanOriginInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, reportedProblemInput))
	}, [reportedProblemInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, typeOfTrafficInput))
	}, [typeOfTrafficInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, situationRecurrentInput))
	}, [situationRecurrentInput])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, workInProgressInput))
	}, [workInProgressInput])

	return (
		<>
			<SelectInput
				id={reportConcernVisualInput.name}
				label={reportConcernVisualInput.label}
				value={reportConcernVisualInput.value}
				list={reportConcernVisualList}
				onChange={onSelectReportConcernVisualInput}
				required
				hasError={errors.includes(reportConcernVisualInput.name)}
				ref={reportConcernVisualInput.ref as RefObject<HTMLSelectElement>}
			/>

			{showSecondQuestion && (
				<SelectInput
					id={reportedProblemInput.name}
					label={reportedProblemInput.label}
					value={reportedProblemInput.value}
					list={reportedProblemList}
					onChange={onSelectReportedProblemInput}
					required={reportedProblemInput.required}
					hasError={errors.includes(reportedProblemInput.name)}
					ref={reportedProblemInput.ref as RefObject<HTMLSelectElement>}
				/>
			)}

			{reportConcernVisualInput.value ===
				pageAssets?.request_form_select_visualPoluttion_street && (
				<SelectInput
					id={typeOfTrafficInput.name}
					label={typeOfTrafficInput.label}
					value={typeOfTrafficInput.value}
					list={typeOfTrafficList}
					onChange={onSelectTypeOfTrafficInput}
				/>
			)}

			<RadioInput
				name={situationRecurrentInput.name}
				label={situationRecurrentInput.label}
				value={situationRecurrentInput.value}
				list={situationRecurrentList}
				onChange={onSelectSituationRecurrentInput}
			/>

			<RadioInput
				name={workInProgressInput.name}
				label={workInProgressInput.label}
				value={workInProgressInput.value}
				list={radioSelectionYesNoDoNotKnow}
				onChange={onSelectWorkInProgressInput}
			/>

			{displayWarningSection(
				pageAssets?.request_form_visualPollution_info_text_label
			)}
		</>
	)
}

export default VisualPollutionForm
