import React, { FC, useState, useEffect } from 'react'
import { RequestFormProps, RequestInputs, SelectList } from '@utils/request'
import SelectInput from '@components/requestForms/inputs/select.inputs'
import requestForm from '@components/requestForms/request.form'

const ShedGazeboSolariumForm: FC<RequestFormProps> = ({
	inputs,
	filledForm,
	onInputsChange
}) => {
	const { pageAssets, UpdateRequestInputsArray } = requestForm()

	const [foundationInput, setFoundationInput] = useState<RequestInputs>({
		name: 'foundationType',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_shedGazeboSolarium_foundationType_label,
		labelKey: 'request_form_shedGazeboSolarium_foundationType_label',
		required: false
	})

	const selectChoices: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.request_form_select_pouring_foundation,
			value: pageAssets?.request_form_select_pouring_foundation,
			key: 'request_form_select_pouring_foundation'
		},
		{
			label: pageAssets?.request_form_select_concrete_blocks,
			value: pageAssets?.request_form_select_concrete_blocks,
			key: 'request_form_select_concrete_blocks'
		},
		{
			label: pageAssets?.request_form_select_concrete_slab,
			value: pageAssets?.request_form_select_concrete_slab,
			key: 'request_form_select_concrete_slab'
		},
		{
			label: pageAssets?.request_form_select_no_foundation,
			value: pageAssets?.request_form_select_no_foundation,
			key: 'request_form_select_no_foundation'
		},
		{
			label: pageAssets?.request_form_select_dont_know,
			value: pageAssets?.request_form_select_dont_know,
			key: 'request_form_select_dont_know'
		}
	]

	const onChangeFoundationInput = (value: string, valueKey: string) =>
		setFoundationInput({ ...foundationInput, value, valueKey })

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, foundationInput))
	}, [foundationInput])

	return (
		<>
			<SelectInput
				id={foundationInput.name}
				value={foundationInput.value}
				label={foundationInput.label}
				list={selectChoices}
				onChange={onChangeFoundationInput}
				filledForm={filledForm}
			/>
		</>
	)
}

export default ShedGazeboSolariumForm
