import React, { FC, useEffect, useState } from 'react'
import { RequestFormProps, RequestInputs } from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import RadioInput from '@components/requestForms/inputs/radio.input'

const PickupBranchForm: FC<RequestFormProps> = ({ inputs, onInputsChange }) => {
	const { pageAssets, UpdateRequestInputsArray, radioSelectionYesNoDoNotKnow } =
		requestForm()

	const [ashBranchesInput, setAshBranchesInput] = useState<RequestInputs>({
		name: 'ashBranches',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_branchPickup_ashBranchesInput_label,
		labelKey: 'request_form_branchPickup_ashBranchesInput_label',
		required: false
	})

	const onSelectAshBranch = (value: string, valueKey: string) =>
		setAshBranchesInput({ ...ashBranchesInput, value, valueKey })

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, ashBranchesInput))
	}, [ashBranchesInput])

	return (
		<>
			<RadioInput
				name={ashBranchesInput.name}
				label={ashBranchesInput.label}
				value={ashBranchesInput.value}
				list={radioSelectionYesNoDoNotKnow}
				onChange={onSelectAshBranch}
			/>
		</>
	)
}

export default PickupBranchForm
