import React, { FC, RefObject, useEffect, useRef, useState } from 'react'
import { RequestFormProps, RequestInputs, SelectList } from '@utils/request'
import requestForm from '@components/requestForms/request.form'
import SelectInput from '@components/requestForms/inputs/select.inputs'
import RadioInput from '@components/requestForms/inputs/radio.input'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'

const MunicipalTaxesForm: FC<RequestFormProps> = ({
	inputs,
	onInputsChange,
	errors,
	onFixError
}) => {
	const {
		pageAssets,
		radioSelectionYesNo,
		UpdateRequestInputsArray,
		UpdateRequestInputsErrorsArray,
		displayWarningSection
	} = requestForm()

	const [topicsType, setTopicsType] = useState<RequestInputs>({
		name: 'taxInformation',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_municipalTaxes_taxInformation_label,
		labelKey: 'request_form_municipalTaxes_taxInformation_label',
		required: true,
		ref: useRef<HTMLSelectElement>(null)
	})

	const [currentSituation, setCurrentSituation] = useState<RequestInputs>({
		name: 'currentSituation',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_municipalTaxes_currentSituation_label,
		labelKey: 'request_form_municipalTaxes_currentSituation_label',
		required: false
	})

	const [receivedMuncTaxesBill, setReceivedMuncTaxesBill] =
		useState<RequestInputs>({
			name: 'receivedMuncTaxesBill',
			value: '',
			valueKey: '',
			label:
				pageAssets?.request_form_municipalTaxes_receivedMuncTaxesBill_label,
			labelKey: 'request_form_municipalTaxes_receivedMuncTaxesBill_label',
			required: false
		})

	const [checkSent, setCheckSent] = useState<RequestInputs>({
		name: 'checkSent',
		value: '',
		valueKey: '',
		label: pageAssets?.request_form_municipalTaxes_checkSent_label,
		labelKey: 'request_form_municipalTaxes_checkSent_label',
		required: false
	})

	const topicsList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.request_form_select_municipal_tax_bill,
			value: REQUEST_CONFIGS_KEYS.municipal.taxBill,
			key: 'request_form_select_municipal_tax_bill'
		},
		{
			label: pageAssets?.request_form_select_payment,
			value: REQUEST_CONFIGS_KEYS.municipal.payment,
			key: 'request_form_select_payment'
		},
		{
			label: pageAssets?.request_form_select_swimming_pool,
			value: REQUEST_CONFIGS_KEYS.municipal.pool,
			key: 'request_form_select_swimming_pool'
		},
		{
			label: pageAssets?.request_form_select_other,
			value: REQUEST_CONFIGS_KEYS.municipal.other,
			key: 'request_form_select_other'
		}
	]

	const currentSituationList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.request_form_select_new_owner,
			value: pageAssets?.request_form_select_new_owner,
			key: 'request_form_select_new_owner'
		},
		{
			label: pageAssets?.request_form_select_first_owner,
			value: pageAssets?.request_form_select_first_owner,
			key: 'request_form_select_first_owner'
		},
		{
			label: pageAssets?.request_form_select_none,
			value: pageAssets?.request_form_select_none,
			key: 'request_form_select_none'
		}
	]

	const checkSentList: SelectList[] = [
		{
			label: '',
			value: '',
			key: ''
		},
		{
			label: pageAssets?.request_form_select_by_mail,
			value: pageAssets?.request_form_select_by_mail,
			key: 'request_form_select_by_mail'
		},
		{
			label: pageAssets?.request_form_select_delivered_by_hand,
			value: pageAssets?.request_form_select_delivered_by_hand,
			key: 'request_form_select_delivered_by_hand'
		},
		{
			label: pageAssets?.request_form_select_chute_exterior_deposit,
			value: pageAssets?.request_form_select_chute_exterior_deposit,
			key: 'request_form_select_chute_exterior_deposit'
		}
	]

	const onChangeTopicsType = (value: string, valueKey: string) => {
		setTopicsType({ ...topicsType, value, valueKey })
		setCurrentSituation({ ...currentSituation, value: '', valueKey: '' })
		setReceivedMuncTaxesBill({
			...receivedMuncTaxesBill,
			value: '',
			valueKey: ''
		})
		setCheckSent({ ...checkSent, value: '', valueKey: '' })
		onFixError(UpdateRequestInputsErrorsArray(errors, topicsType.name))
	}

	const onChangeCurrentSituation = (value: string, valueKey: string) =>
		setCurrentSituation({ ...currentSituation, value, valueKey })

	const onChangeReceivedMuncTaxesBill = (value: string, valueKey: string) =>
		setReceivedMuncTaxesBill({ ...receivedMuncTaxesBill, value, valueKey })

	const onChangeCheckSent = (value: string, valueKey: string) =>
		setCheckSent({ ...checkSent, value, valueKey })

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, topicsType))
	}, [topicsType])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, currentSituation))
	}, [currentSituation])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, receivedMuncTaxesBill))
	}, [receivedMuncTaxesBill])

	useEffect(() => {
		onInputsChange(UpdateRequestInputsArray(inputs, checkSent))
	}, [checkSent])

	return (
		<>
			<SelectInput
				id={topicsType.name}
				value={topicsType.value}
				label={topicsType.label}
				list={topicsList}
				required
				onChange={onChangeTopicsType}
				hasError={errors.includes(topicsType.name)}
				ref={topicsType.ref as RefObject<HTMLSelectElement>}
			/>
			{(topicsType.value === REQUEST_CONFIGS_KEYS.municipal.taxBill ||
				topicsType.value === REQUEST_CONFIGS_KEYS.municipal.other) && (
				<>
					<SelectInput
						id={currentSituation.name}
						value={currentSituation.value}
						label={currentSituation.label}
						list={currentSituationList}
						onChange={onChangeCurrentSituation}
					/>
					<RadioInput
						name={receivedMuncTaxesBill.name}
						value={receivedMuncTaxesBill.value}
						label={receivedMuncTaxesBill.label}
						list={radioSelectionYesNo}
						onChange={onChangeReceivedMuncTaxesBill}
					/>
				</>
			)}
			{topicsType.value === REQUEST_CONFIGS_KEYS.municipal.payment && (
				<>
					<SelectInput
						id={checkSent.name}
						value={checkSent.value}
						label={checkSent.label}
						list={checkSentList}
						onChange={onChangeCheckSent}
					/>

					{displayWarningSection(
						pageAssets?.request_form_speed_signaling_info_text_paiement_label
					)}
				</>
			)}
			{topicsType.value === REQUEST_CONFIGS_KEYS.municipal.pool &&
				displayWarningSection(
					pageAssets?.request_form_speed_signaling_info_text_swimming_pool_label
				)}
		</>
	)
}

export default MunicipalTaxesForm
