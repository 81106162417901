import React, { FC } from 'react'
import {
	Component,
	Components,
	RequestFormProps,
	RequestNames
} from '@utils/request'
import ShedGazeboSolariumForm from '@components/requestForms/shedGazeboSolarium.form'
import ConstructionStandardsFloodRiskForm from '@components/requestForms/constructionStandardsFloodRisk.form'
import MiscellaneousEquipmentForm from '@components/requestForms/miscellaneousEquipment.form'
import SwimmingPoolSpaForm from '@components/requestForms/swimmingPoolSpa.form'
import CrossingGuardAndSchoolForm from '@components/requestForms/crossingGuardAndSchool.form'
import RenovationConstructionBuildingForm from '@components/requestForms/renovationConstructionBuilding.form'
import PickupBranchForm from '@components/requestForms/pickupBranch.form'
import AuthorisedBusinessesForm from '@components/requestForms/authorisedBusinesses.form'
import OthersRequestsForm from '@components/requestForms/othersRequests.form'
import EvaluateTreeForm from '@components/requestForms/evaluateTree.form'
import FloodingForm from '@components/requestForms/flooding.form'
import SpeedAndSignalingForm from '@components/requestForms/speedAndSignaling.form'
import MunicipalTaxesForm from '@components/requestForms/municipalTaxes.form'
import LandscapingAndMaintenanceForm from '@components/requestForms/landscapingAndMaintenance.form'
import MaterialsOrWasteNotCollectedForm from '@components/requestForms/materialsOrWasteNotCollected.form'
import BinLostStoleIncorrectlyReplaceForm from '@components/requestForms/binLostStoleIncorrectlyReplace.form'
import PotHoleForm from '@components/requestForms/pothole.form'
import PandemicEpidemicForm from '@components/requestForms/pandemicEpidemic.form'
import NewBinAddOrChangeForm from '@components/requestForms/newBinAddOrChange.form'
import BrokenBinForm from '@components/requestForms/brokenBin.form'
import LandRegistryModificationForm from '@components/requestForms/landRegistryModification.form'
import VisualPollutionForm from '@components/requestForms/visualPollution.form'
import CurbAndSidewalkForm from '@components/requestForms/curbAndSidewalk.form'
import ZoningForm from '@components/requestForms/zoning.form'
import FloorLampAndLighting from '@components/requestForms/floorLampAndLighting.form'
import ObligationsAndStandardsForm from '@components/requestForms/obligationsAndStandards.form'
import PanelsAndSignalingForm from '@components/requestForms/panelsAndSignaling.form'
import SnowClearingProblemForm from './snowClearingProblem.form'
import TrafficLightAndPedestrianLight from './trafficLightAndPedestrianLight.form'
import TreeDonationForm from './treeDonation.form'

type Props = RequestFormProps & {
	name: string
}

const Selection: FC<Props> = ({
	name,
	inputs,
	errors,
	filledForm,
	onInputsChange,
	onFixError
}) => {
	const formFactory: Components = {
		[RequestNames.shedGazeboSolarium]: ShedGazeboSolariumForm,
		[RequestNames.constructionStandardsFloodRisk]:
			ConstructionStandardsFloodRiskForm,
		[RequestNames.miscellaneousEquipment]: MiscellaneousEquipmentForm,
		[RequestNames.swimmingPoolSpa]: SwimmingPoolSpaForm,
		[RequestNames.crossingGuardAndSchool]: CrossingGuardAndSchoolForm,
		[RequestNames.renovationConstructionBuilding]:
			RenovationConstructionBuildingForm,
		[RequestNames.branchPickup]: PickupBranchForm,
		[RequestNames.evaluateTree]: EvaluateTreeForm,
		[RequestNames.authorisedBusinesses]: AuthorisedBusinessesForm,
		[RequestNames.flooding]: FloodingForm,
		[RequestNames.speedAndSignaling]: SpeedAndSignalingForm,
		[RequestNames.municipalTaxes]: MunicipalTaxesForm,
		[RequestNames.landscapingAndMaintenance]: LandscapingAndMaintenanceForm,
		[RequestNames.materialsOrWasteNotCollected]:
			MaterialsOrWasteNotCollectedForm,
		[RequestNames.binLostStoleIncorrectlyReplace]:
			BinLostStoleIncorrectlyReplaceForm,
		[RequestNames.pothole]: PotHoleForm,
		[RequestNames.pandemicEpidemic]: PandemicEpidemicForm,
		[RequestNames.newBinAddOrChange]: NewBinAddOrChangeForm,
		[RequestNames.brokenBin]: BrokenBinForm,
		[RequestNames.landRegistryModification]: LandRegistryModificationForm,
		[RequestNames.visualPollution]: VisualPollutionForm,
		[RequestNames.curbAndSidewalk]: CurbAndSidewalkForm,
		[RequestNames.zoning]: ZoningForm,
		[RequestNames.floorLampAndLighting]: FloorLampAndLighting,
		[RequestNames.obligationsAndStandards]: ObligationsAndStandardsForm,
		[RequestNames.panelsAndSignaling]: PanelsAndSignalingForm,
		[RequestNames.othersRequests]: OthersRequestsForm,
		[RequestNames.snowClearingProblem]: SnowClearingProblemForm,
		[RequestNames.trafficLightAndPedestrianLight]:
			TrafficLightAndPedestrianLight,
		[RequestNames.treeDonation]: TreeDonationForm
	}

	const ComponentForm: Component = formFactory[name] || undefined

	if (!ComponentForm) {
		return <></>
	}

	return (
		<ComponentForm
			inputs={inputs}
			errors={errors}
			onInputsChange={onInputsChange}
			onFixError={onFixError}
			filledForm={filledForm}
		/>
	)
}

export default Selection
